import styles from "./staffListCustomFilter.module.scss";
import { FilterType } from "stores/utils/types/FilterType";
import { Col } from "stores/utils/types/Col";
import FilterMainCols from "features/TableWithStickyFilter/Filter/FilterMain/FilterMainCols";
import Select from "shared/ui/Inputs/Select";
import DateRangePicker from "shared/ui/Inputs/DateRangePicker";
import DatePickerField from "shared/ui/Inputs/DatePickerField";
import { Input } from "shared/ui/Inputs/Input";
import { useFormikContext } from "formik";
import { useStores } from "stores";
import { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { ViewField } from "shared/ui/ViewField";

type StaffListCustomFilterProps = Partial<{
  selectedFilter: string;
  openedListName: string;
  valueField: string;
  values: FilterType;
  params: { [key: string]: Col };
  selectMulti: string[];
  dateRangeFields: Record<string, string[]>;
  changeValue: (
    key: string,
    value: string | number | string[] | FilterType["filter"]
  ) => void;
  changeOpenedWindows: (arg: string) => void;
  dictForArray: Record<string, string>;
  setDictForArray: (value: Record<string, string>) => void;
}>;

const StaffListCustomFilter = ({
  selectedFilter,
  openedListName,
  valueField,
  values,
  params,
  selectMulti,
  dateRangeFields,
  changeValue,
  changeOpenedWindows,
  dictForArray,
  setDictForArray
}: StaffListCustomFilterProps) => {
  const { staffListStore, filterStore } = useStores();
  const { handleChange } = useFormikContext();

  // в данном useEffect заполняем словарь для расширенного поиска, если в url присутствуют параметры фильтрации, переходя по ссылке или обновляя страницу
  // применяем для полей, пагинация которых происходит с бэка (объект, должность)
  useEffect(() => {
    if (
      Array.isArray(values.filter["building"]) &&
      values.filter["building"].length
    ) {
      // согласно значению поля Объект записываем в словарь эти объекты
      staffListStore.getBuildingDictForSavedFilter(values.filter["building"]);
      setDictForArray(staffListStore.dictForArray);
    }
    if (
      Array.isArray(values.filter["position"]) &&
      values.filter["position"].length
    ) {
      // согласно значению поля Должность записываем в словарь эти должности
      staffListStore.getPositionDictForSavedFilter(values.filter["position"]);
      setDictForArray(staffListStore.dictForArray);
    }
    if (
      Array.isArray(values.filter["company"]) &&
      values.filter["company"].length
    ) {
      // согласно значению поля Компании обновляем выпадающий список должностей и объектов
      getBuildingListFromCompany(values.filter["company"]);
      getPositionListFromCompany(values.filter["company"]);
    }
  }, [staffListStore.staffTableParams["company"]]);

  // в данном useEffect заполняем словарь для сохраненного фильтра для тех полей, пагинация которых происходит с бэка (объект, должность)
  useEffect(() => {
    if (
      Array.isArray(staffListStore.filterParams["filter[building]"]) &&
      staffListStore.filterParams["filter[building]"].length &&
      filterStore.isOpenedSavedFilterOptions
    ) {
      // согласно значению поля Объект в сохраненном фильтре записываем в словарь эти объекты
      staffListStore.getBuildingDictForSavedFilter(
        staffListStore.filterParams["filter[building]"]
      );
      setDictForArray(staffListStore.dictForArray);
    }
    if (
      Array.isArray(staffListStore.filterParams["filter[position]"]) &&
      staffListStore.filterParams["filter[position]"].length &&
      filterStore.isOpenedSavedFilterOptions
    ) {
      // согласно значению поля Должность в сохраненном фильтре записываем в словарь эти должности
      staffListStore.getPositionDictForSavedFilter(
        staffListStore.filterParams["filter[position]"]
      );
      setDictForArray(staffListStore.dictForArray);
    }
    if (
      Array.isArray(staffListStore.filterParams["filter[company]"]) &&
      staffListStore.filterParams["filter[company]"].length &&
      filterStore.isOpenedSavedFilterOptions
    ) {
      // согласно значению поля Компании в сохраненном фильтре обновляем выпадающий список должностей и объектов
      getBuildingListFromCompany(
        staffListStore.filterParams["filter[company]"]
      );
      getPositionListFromCompany(
        staffListStore.filterParams["filter[company]"]
      );
    }
  }, [filterStore.isOpenedSavedFilterOptions, staffListStore.downloadedFilter]);

  // пагинация для выпадающего списка объектов
  useEffect(() => {
    if (
      staffListStore.pageBuilding !== 1 &&
      staffListStore.pageBuilding <= staffListStore.maxPageBuilding &&
      staffListStore.pageBuilding !== staffListStore.prevPageBuilding
    ) {
      staffListStore.getMoreBuildingList();
    }
  }, [staffListStore.pageBuilding, staffListStore.maxPageBuilding]);

  // пагинация для выпадающего списка должностей
  useEffect(() => {
    if (
      staffListStore.pagePosition !== 1 &&
      staffListStore.pagePosition <= staffListStore.maxPagePosition &&
      staffListStore.pagePosition !== staffListStore.prevPagePosition
    ) {
      staffListStore.getMorePositionList();
    }
  }, [staffListStore.pagePosition, staffListStore.maxPagePosition]);

  // при обнулении поля Компания при нажатии на кнопку "Сохранить" обновляются выпадающие списки объектов и должностей
  useEffect(() => {
    if (
      Array.isArray(values.filter["company"]) &&
      !values.filter["company"].length
    ) {
      getBuildingListFromCompany([]);
      getPositionListFromCompany([]);
    }
  }, [values.filter?.["company"]]);

  // Функция для изменения списка компаний в зависимости от выбранного объекта
  // При выборе объекта сразу проставляется значение поля company выбранного объекта в поле "Компания"
  const changeCompanyFromBuilding = (value: string[]) => {
    const dict = params["building"]?.directory;
    if (Array.isArray(dict)) {
      // получение списка компаний из выбранных объектов, где каждое значение является уникальным
      const companyValue = Array.from(
        new Set(
          value.map((buildingID) => {
            return dict.filter((item) => buildingID === item["newname"])[0]?.[
              "company"
            ];
          })
        )
      );
      changeValue("filter.company", companyValue);
      getPositionListFromCompany(companyValue);

      getNewValueFromCompany(companyValue, "position");
    }
  };

  // функция для подтягивания нового списка объектов в том случае, если первым заполняется поле Компания
  const getBuildingListFromCompany = (value: string[]) => {
    staffListStore.setSearchValueBuilding("");
    staffListStore.setCompanyListForBuilding(value);
    staffListStore.getBuildingList();
  };

  // функция для подтягивания нового списка должностей в зависимости от выбранных компаний
  const getPositionListFromCompany = (value: string[]) => {
    staffListStore.setSearchValuePosition("");
    staffListStore.setCompanyListForPosition(value);
    staffListStore.getPositionList();
  };

  //функции для изменения значений полей Должность или Объект
  const getNewValueFromCompany = (
    companyList: string[],
    field: "position" | "building"
  ) => {
    // проверяем, есть ли в поле "Должность"/"Объект" выбранные значения
    if ((values.filter[field] as string[]).length) {
      const fieldValue = (values.filter[field] as string[]).filter(
        (fieldID) => {
          return companyList.includes(
            (
              params[field].directory as {
                [key: string]: string;
                newname: string;
                title: string;
              }[]
            ).filter((item) => fieldID === item["newname"])[0]["company"]
          );
        }
      );
      // если есть, то обновляем значения поля согласно полученнуму списку компаний companyList
      changeValue(`filter.${field}`, fieldValue);
    }
  };
  return (
    <>
      {Object.keys(values.filter).map((key) => {
        const field = params[key];

        if (field?.directory) {
          const dict = field.directory;

          if (selectMulti && selectMulti.includes(key)) {
            switch (key) {
              case "building":
                return (
                  <FilterMainCols
                    key={key}
                    array={[...Object.values(values["filter"][key])]}
                    directory={dict}
                    deleteItem={(value) => {
                      const newArray = (
                        Object.values(values["filter"][key]) as string[]
                      )?.filter((item) => item !== value);
                      changeValue(`filter.${key}`, newArray);
                      !staffListStore.companyListForBuilding.length &&
                        changeCompanyFromBuilding(newArray);
                    }}
                    deleteAllItem={() => {
                      changeValue(`filter.${key}`, []);
                      if (!staffListStore.companyListForBuilding.length) {
                        changeValue("filter.company", []);
                        changeValue("filter.position", []);
                        getPositionListFromCompany([]);
                      }
                    }}
                    updateItems={(value) => {
                      changeValue(`filter.${key}`, value);
                      !staffListStore.companyListForBuilding.length &&
                        changeCompanyFromBuilding(value);
                    }}
                    isMenuOpened={openedListName === `filter.${key}`}
                    setIsMenuOpened={() => {
                      changeOpenedWindows(`filter.${key}`);
                    }}
                    placeholder={field.title}
                    label={field.title}
                    selectedFilter={selectedFilter}
                    isSearchWithPagination
                    pageSelectsInfo={{
                      building: {
                        page: staffListStore.pageBuilding,
                        prevPage: staffListStore.prevPageBuilding,
                        maxPage: staffListStore.maxPageBuilding,
                        searchValue: staffListStore.searchValueBuilding,
                        isLoading: staffListStore.isLoadingForBuildingList,
                        setSearchValue: staffListStore.setSearchValueBuilding,
                        setPage: staffListStore.setPageBuilding,
                        getList: staffListStore.getBuildingList
                      }
                    }}
                    field={key}
                    dictForArray={dictForArray}
                    setDictForArray={setDictForArray}
                  />
                );

              case "company":
                return (
                  <FilterMainCols
                    key={key}
                    array={[...Object.values(values["filter"][key])]}
                    directory={dict}
                    deleteItem={(value) => {
                      const newArray = (
                        Object.values(values["filter"][key]) as string[]
                      )?.filter((item) => item !== value);
                      changeValue(`filter.${key}`, newArray);

                      getNewValueFromCompany(newArray, "building");

                      getNewValueFromCompany(newArray, "position");

                      getPositionListFromCompany(newArray);
                      if (staffListStore.companyListForBuilding.length) {
                        getBuildingListFromCompany(newArray);
                      }
                    }}
                    deleteAllItem={() => {
                      changeValue(`filter.${key}`, []);
                      changeValue("filter.building", []);
                      changeValue("filter.position", []);
                      if (staffListStore.companyListForBuilding.length) {
                        getBuildingListFromCompany([]);
                      }
                      getPositionListFromCompany([]);
                    }}
                    updateItems={(value) => {
                      changeValue(`filter.${key}`, value);
                      if (
                        !(values.filter["building"] as string[])?.length ||
                        staffListStore.companyListForBuilding.length
                      ) {
                        getBuildingListFromCompany(value);
                        getPositionListFromCompany(value);
                      }
                    }}
                    isMenuOpened={openedListName === `filter.${key}`}
                    setIsMenuOpened={() => {
                      changeOpenedWindows(`filter.${key}`);
                    }}
                    placeholder={field.title}
                    label={field.title}
                    selectedFilter={selectedFilter}
                  />
                );

              case "position":
                if (
                  !values.filter["company"] ||
                  (Array.isArray(values.filter["company"]) &&
                    !values.filter["company"].length)
                ) {
                  return (
                    <div
                      className={styles.tooltip}
                      data-tooltip="Сперва необходимо выбрать Компанию"
                    >
                      <ViewField
                        title={field?.title}
                        value={undefined}
                        disabled
                      />
                    </div>
                  );
                } else {
                  return (
                    <FilterMainCols
                      key={key}
                      array={[...Object.values(values["filter"][key])]}
                      directory={dict}
                      deleteItem={(value) => {
                        const newArray = (
                          Object.values(values["filter"][key]) as string[]
                        )?.filter((item) => item !== value);
                        changeValue(`filter.${key}`, newArray);
                      }}
                      deleteAllItem={() => changeValue(`filter.${key}`, [])}
                      updateItems={(value) => {
                        changeValue(`filter.${key}`, value as string[]);
                      }}
                      isMenuOpened={openedListName === `filter.${key}`}
                      setIsMenuOpened={() => {
                        changeOpenedWindows(`filter.${key}`);
                      }}
                      placeholder={field.title}
                      label={field.title}
                      selectedFilter={selectedFilter}
                      isSearchWithPagination
                      pageSelectsInfo={{
                        position: {
                          page: staffListStore.pagePosition,
                          prevPage: staffListStore.prevPagePosition,
                          maxPage: staffListStore.maxPagePosition,
                          searchValue: staffListStore.searchValuePosition,
                          isLoading: staffListStore.isLoadingForPositionList,
                          setSearchValue: staffListStore.setSearchValuePosition,
                          setPage: staffListStore.setPagePosition,
                          getList: staffListStore.getPositionList
                        }
                      }}
                      field={key}
                      dictForArray={dictForArray}
                      setDictForArray={setDictForArray}
                    />
                  );
                }

              default:
                return (
                  <FilterMainCols
                    key={key}
                    array={[...Object.values(values["filter"][key])]}
                    directory={dict}
                    deleteItem={(value) => {
                      const newArray = (
                        Object.values(values["filter"][key]) as string[]
                      )?.filter((item) => item !== value);
                      changeValue(`filter.${key}`, newArray);
                    }}
                    deleteAllItem={() => changeValue(`filter.${key}`, [])}
                    updateItems={(value) => {
                      changeValue(`filter.${key}`, value as string[]);
                    }}
                    isMenuOpened={openedListName === `filter.${key}`}
                    setIsMenuOpened={() => {
                      changeOpenedWindows(`filter.${key}`);
                    }}
                    placeholder={field.title}
                    label={field.title}
                    selectedFilter={selectedFilter}
                  />
                );
            }
          } else {
            return (
              <Select
                key={key}
                name={`filter.${key}`}
                title={field.title}
                label={
                  values.filter[key] && values.filter[key] !== -1
                    ? dict[values.filter[key] as string]
                      ? dict[values.filter[key] as string].title
                      : (values.filter[key] as string)
                    : null
                }
                options={dict}
                onClick={(value) => {
                  changeValue(`filter.${key}`, value[valueField] as string);
                }}
                isFloating
                valueName={valueField}
              />
            );
          }
        }

        if (field?.type === "date") {
          if (dateRangeFields && key in dateRangeFields) {
            return (
              <DateRangePicker
                key={key}
                monthsShown={2}
                name={`filter.${dateRangeFields[key][0]}`}
                title={field.title}
                isCalendarOpened={openedListName === key}
                setIsCalendarOpened={() => {
                  changeOpenedWindows(key);
                }}
                placeholderVisible
                siblingDateName={`filter.${dateRangeFields[key][1]}`}
              />
            );
          } else {
            return (
              <DatePickerField
                key={key}
                name={`filter.${key}`}
                title={field.title}
                isCalendarOpened={openedListName === key}
                setIsCalendarOpened={() => {
                  changeOpenedWindows(key);
                }}
                onBlur={() => {
                  changeOpenedWindows(null);
                }}
              />
            );
          }
        }

        if (
          dateRangeFields &&
          Object.values(dateRangeFields).flat().includes(key)
        ) {
          return;
        }

        if (field?.type === "bool") {
          return (
            <div className={styles.input} key={key}>
              <Select
                name={`filter.${key}`}
                title={field.title}
                isFloating
                options={{
                  1: { newname: "1", title: "Да" },
                  0: { newname: "0", title: "Нет" }
                }}
                onClick={(option) => {
                  changeValue(`filter.${key}`, option.newname as string);
                }}
              />
            </div>
          );
        }

        if (key === "count_work_all") {
          return (
            <div
              key={key}
              className={styles.tooltip}
              data-tooltip="Сперва необходимо выбрать Компанию, Должность, Объект и Статус"
            >
              <ViewField title={field?.title} value={undefined} disabled />
            </div>
          );
        }

        return (
          <div className={styles.input} key={key}>
            <Input
              name={`filter.${key}`}
              onChange={(e) => {
                handleChange(e);
                changeValue(`filter.${key}`, e.target.value);
              }}
              label={field?.title}
            />
          </div>
        );
      })}
    </>
  );
};

export default observer(StaffListCustomFilter);
