import styles from "./staffOneEditSettingForm.module.scss";
import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import { Form } from "react-bootstrap";
import { Formik } from "formik";
import { validationEditSetting } from "./validation";
import addIcon from "shared/assets/images/mainIcons/iconAdd/iconAdd.svg";
import RadioButton from "shared/ui/Inputs/RadioButton";
import { Input } from "shared/ui/Inputs/Input";
import Checkbox from "shared/ui/Inputs/Checkbox";
import ButtonsGroupForEdit from "shared/ui/ButtonsGroup/ButtonsGroupForEdit";
import Modal from "shared/ui/Modal";
import { PhoneInput } from "shared/ui/Inputs/PhoneInput";
import { Button, ButtonSize, ButtonTheme } from "shared/ui/Button";
import { getValues } from "shared/utils/helpers/getValues";
import { useParams } from "react-router-dom";
import { diff } from "deep-object-diff";
import { getEntries } from "shared/utils/helpers/getEntries";

type PhoneType = {
  number: number | string | null;
  comment: string | null;
  confirm: number | null;
}[];

type StaffOneEditSettingFormProps = {
  setShowSuccessModal: (arg: boolean) => void;
  setIsOpenForm: (arg: boolean) => void;
};

type ModalAction = "delete" | "cancelAdd" | "editCancel" | "formCancel" | null;

const StaffOneEditSettingForm = ({
  setShowSuccessModal,
  setIsOpenForm
}: StaffOneEditSettingFormProps) => {
  const { staffOneEditStore } = useStores();
  const { id } = useParams();

  const [contacts, setContacts] = useState<{ phones?: PhoneType }>({
    phones: []
  });
  const [selectedContact, setSelectedContact] = useState<number | null>(null);
  const [newPhoneIndex, setNewPhoneIndex] = useState<number | null>(null);
  const [isAddPhone, setIsAddPhone] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [showModal, setShowModal] = useState<ModalAction>(null);
  const [modalInfo, setModalInfo] = useState<{
    action: ModalAction;
    idx: number | null;
  }>({
    action: null,
    idx: null
  });

  useEffect(() => {
    if (Object.keys(staffOneEditStore.selectedOneForEdit).length) {
      setContacts({
        phones: getValues(staffOneEditStore.selectedOneForEdit.phone)
      });
    }
  }, [staffOneEditStore.selectedOneForEdit]);

  return (
    <>
      <Formik
        initialValues={{
          contacts: contacts,
          selectedPhone: null
        }}
        validateOnBlur
        validateOnChange
        validationSchema={validationEditSetting}
        enableReinitialize
        onSubmit={() => null}
      >
        {({
          values,
          initialValues,
          setFieldValue,
          setFieldTouched,
          handleChange,
          handleBlur,
          dirty,
          isValid
        }) => {
          const handleModalConfirm = () => {
            if (showModal === "delete" && modalInfo.idx !== null) {
              staffOneEditStore.deletePhone(id, modalInfo.idx);
            } else if (showModal === "editCancel" && modalInfo.idx !== null) {
              setFieldValue(
                `contacts.phones[${modalInfo.idx}]`,
                initialValues.contacts.phones[modalInfo.idx]
              );
              setSelectedContact(null);
            } else if (showModal === "cancelAdd" && newPhoneIndex !== null) {
              const updatedPhones = [...values.contacts.phones];
              updatedPhones.splice(newPhoneIndex, 1);
              setFieldValue("contacts.phones", updatedPhones);
              setNewPhoneIndex(null);
              setIsAddPhone(false);
            } else if (showModal === "formCancel") {
              setIsOpenForm(false);
            }
            setShowModal(null);
            setModalInfo({ action: null, idx: null });
          };

          const handleModalClose = () => {
            setShowModal(null);
            setModalInfo({ action: null, idx: null });
          };

          const handleModalSave = async () => {
            if (showModal === "editCancel" && modalInfo.idx !== null) {
              await editContact({ action: "submit", idx: modalInfo.idx });
            } else if (showModal === "cancelAdd" && newPhoneIndex !== null) {
              await addPhoneHandler("submit");
            } else if (showModal === "formCancel") {
              if (selectedContact !== null) {
                await editContact({ action: "submit", idx: selectedContact });
              } else if (isAddPhone && newPhoneIndex !== null) {
                await addPhoneHandler("submit");
              }
              setIsOpenForm(false);
            }
            setShowModal(null);
            setModalInfo({ action: null, idx: null });
          };

          const handleNewPhoneAction = (action: string) => {
            const contactObject: { phones?: PhoneType } = {};
            const contactArray = [...values.contacts.phones];

            switch (action) {
              case "add":
                contactArray.push({
                  number: "",
                  comment: "",
                  confirm: 0
                });
                setNewPhoneIndex(contactArray.length - 1);
                setSelectedContact(contactArray.length - 1);
                setIsEmpty(false);
                break;
              default:
                break;
            }

            contactObject.phones = contactArray;
            setFieldValue("contacts", contactObject);
            setContacts(contactObject);
          };

          const handleSendData = (event?: React.FormEvent) => {
            event && event.preventDefault();
            Promise.all([
              staffOneEditStore.resetPassword(
                staffOneEditStore.selectedOneForEdit.id,
                values.selectedPhone
              )
            ]).then(() => {
              setFieldValue("selectedPhone", null);
              setIsOpenForm(false);
              setIsAddPhone(false);
              setShowSuccessModal(true);
              setSelectedContact(null);
            });
          };

          const handleResetData = () => {
            if (dirty || isAddPhone) {
              setShowModal("formCancel");
              setModalInfo({ action: "formCancel", idx: null });
            } else {
              setIsOpenForm(false);
            }
          };

          const editContact = async ({
            action,
            idx
          }: {
            action: string;
            idx: number;
          }) => {
            switch (action) {
              case "submit": {
                const phones = [...values.contacts.phones];
                const changes = getEntries(
                  diff(initialValues.contacts?.phones?.[idx] || {}, phones[idx])
                );
                setSelectedContact(null);

                if (changes?.length > 1) {
                  await staffOneEditStore.editPhone(
                    "phone",
                    id,
                    idx,
                    "number",
                    phones[idx]?.number,
                    phones[idx]?.comment
                  );
                } else if (changes?.length === 1) {
                  await staffOneEditStore.editPhone(
                    "phone",
                    id,
                    idx,
                    changes[0]?.[0],
                    changes[0]?.[1]
                  );
                }
                setIsEmpty(false);
                break;
              }
              case "edit":
                setSelectedContact(idx);
                break;
              case "delete":
                setShowModal("delete");
                setModalInfo({ action: "delete", idx });
                break;
              case "cancel":
                if (isAddPhone) {
                  setShowModal("cancelAdd");
                  setModalInfo({ action: "cancelAdd", idx: newPhoneIndex });
                } else {
                  const currentContact = values.contacts.phones[idx];
                  const initialContact = initialValues.contacts.phones[idx];

                  const changes = getEntries(
                    diff(initialContact || {}, currentContact)
                  );

                  if (changes.length > 0) {
                    setShowModal("editCancel");
                    setModalInfo({ action: "editCancel", idx });
                  } else {
                    setSelectedContact(null);
                  }
                }
                break;
              default:
                break;
            }
          };

          const addPhoneHandler = async (action: string) => {
            if (action === "submit") {
              const phones = [...values.contacts.phones];
              const newPhoneEntry = phones[newPhoneIndex as number];
              await staffOneEditStore.addPhone(
                id,
                newPhoneEntry.number,
                newPhoneEntry.comment
              );
              setSelectedContact(null);
              setIsAddPhone(false);
            } else {
              const newPhoneEntry =
                values.contacts.phones[newPhoneIndex as number];

              const isPhoneEmpty =
                !newPhoneEntry.number && !newPhoneEntry.comment;

              if (isPhoneEmpty) {
                const updatedPhones = [...values.contacts.phones];
                updatedPhones.splice(newPhoneIndex as number, 1);
                setFieldValue("contacts.phones", updatedPhones);
                setNewPhoneIndex(null);
                setIsAddPhone(false);
              } else {
                setShowModal("cancelAdd");
                setModalInfo({ action: "cancelAdd", idx: newPhoneIndex });
              }
            }
          };

          return (
            <Form className={styles.form}>
              <div className={styles.phoneContainer}>
                <div className={styles.phoneContainerContent}>
                  <div>У сотрудника будет сброшен установленный пароль</div>
                  <div>
                    Выберите номер телефона, на который необходимо отправить
                    новый пароль сотрудника
                  </div>
                </div>

                {!staffOneEditStore.isLoadingSettings ? (
                  <>
                    {Object.keys(values.contacts)?.length && (
                      <div className={styles.containerForm}>
                        {values.contacts.phones.map((contact, i) => {
                          return (
                            <ul
                              key={`contact_${i}`}
                              className={
                                isAddPhone && i === newPhoneIndex
                                  ? `${styles.confirmRowAddPhone} ${styles.confirmRowAddPhone_selected}`
                                  : selectedContact === i
                                  ? `${styles.confirmRowPhone} ${styles.confirmRowPhone_selected}`
                                  : styles.confirmRowPhone
                              }
                            >
                              {(isAddPhone && i === newPhoneIndex) ||
                              isEmpty ? null : (
                                <>
                                  <li>
                                    <RadioButton
                                      name="selectedPhone"
                                      value={contact["number"].toString()}
                                      onChange={(e) => {
                                        handleChange(e);
                                      }}
                                    />
                                  </li>
                                  <li>
                                    <label
                                      htmlFor={`contacts.phones.${i}.confirm`}
                                      className={styles.confirmLabel}
                                    >
                                      Подтвержд.
                                    </label>
                                    <Checkbox
                                      name={`contacts.phones.${i}.confirm`}
                                      id={`contacts.phones.${i}.confirm`}
                                      disabled
                                    />
                                  </li>
                                </>
                              )}
                              <li>
                                <PhoneInput
                                  name={`contacts.phones.${i}.number`}
                                  label={
                                    staffOneEditStore.staffTableCols["phone"][
                                      "title"
                                    ]
                                  }
                                  notEditable={selectedContact !== i}
                                  required={selectedContact === i}
                                  fromAppSettings
                                />
                              </li>
                              <li>
                                <Input
                                  name={`contacts.phones.${i}.comment`}
                                  label={
                                    staffOneEditStore.staffTableCols["comment"][
                                      "title"
                                    ]
                                  }
                                  onChange={(e) => {
                                    handleChange(e);
                                    setFieldTouched(
                                      `contacts.phones.${i}.comment`
                                    );
                                  }}
                                  onBlur={handleBlur}
                                  disabled={selectedContact !== i}
                                />
                              </li>
                              <li>
                                {(isAddPhone && newPhoneIndex === i) ||
                                isEmpty ? (
                                  <ButtonsGroupForEdit
                                    id={`contact_${i}`}
                                    idx={i}
                                    btns={[
                                      {
                                        action: "submit",
                                        type: "button",
                                        icon: "bigcheck",
                                        color:
                                          !isValid || !dirty
                                            ? "bw-gray3"
                                            : "blue-lazure",
                                        disabled: !isValid || !dirty
                                      },
                                      {
                                        action: "cancel",
                                        type: "button",
                                        icon: "iconclose",
                                        color: "bw-gray5",
                                        disabled: false
                                      }
                                    ]}
                                    onClick={addPhoneHandler}
                                  />
                                ) : (
                                  <ButtonsGroupForEdit
                                    id={`contact_${i}`}
                                    idx={i}
                                    btns={
                                      selectedContact === i
                                        ? [
                                            {
                                              action: "submit",
                                              type: "button",
                                              icon: "bigcheck",
                                              color:
                                                !isValid || !dirty
                                                  ? "bw-gray3"
                                                  : "blue-lazure",
                                              disabled: !isValid || !dirty
                                            },
                                            {
                                              action: "cancel",
                                              type: "button",
                                              icon: "iconclose",
                                              color: "bw-gray5",
                                              disabled: false
                                            },
                                            {
                                              action: "delete",
                                              type: "button",
                                              icon: "iconbasket",
                                              color: "bw-gray5",
                                              disabled: false
                                            }
                                          ]
                                        : [
                                            {
                                              action: "edit",
                                              type: "button",
                                              icon: "iconedit",
                                              color: "bw-gray5",
                                              disabled: false
                                            },
                                            {
                                              action: "delete",
                                              type: "button",
                                              icon: "iconbasket",
                                              color: "bw-gray5",
                                              disabled: false
                                            }
                                          ]
                                    }
                                    onClick={(action, idx) => {
                                      editContact({ action, idx });
                                    }}
                                  />
                                )}
                              </li>
                            </ul>
                          );
                        })}
                        {!isAddPhone && !isEmpty ? (
                          <button
                            type="button"
                            className={styles.buttonAddNumber}
                            onClick={() => {
                              setIsAddPhone(true);
                              handleNewPhoneAction("add");
                            }}
                          >
                            <p className={styles.buttonAddNumber__text}>
                              Добавить номер
                            </p>
                            <img
                              alt="icon"
                              src={addIcon}
                              className={styles.buttonAddNumber__icon}
                            />
                          </button>
                        ) : null}
                      </div>
                    )}
                  </>
                ) : (
                  <p className={styles.loading}>Пожалуйста, подождите</p>
                )}

                <div className={styles.buttonsWrapper}>
                  <Button
                    theme={ButtonTheme.SECONDARY}
                    size={ButtonSize.M}
                    disabled={values.selectedPhone === null}
                    onClick={handleSendData}
                  >
                    Отправить
                  </Button>
                  <Button
                    theme={ButtonTheme.CANCELLED}
                    size={ButtonSize.M}
                    onClick={handleResetData}
                    type="button"
                  >
                    Отмена
                  </Button>
                </div>
              </div>
              {showModal && (
                <Modal
                  type={
                    showModal === "delete"
                      ? "clarification"
                      : "clarificationForFormWindows"
                  }
                  show={showModal !== null}
                  onHide={handleModalClose}
                  title={
                    showModal === "delete"
                      ? "Вы уверены, что хотите удалить номер телефона?"
                      : "Внесены изменения."
                  }
                  subtitle={
                    showModal === "editCancel" ||
                    showModal === "cancelAdd" ||
                    showModal === "formCancel"
                      ? "Вы уверены, что хотите закрыть без сохранения изменений?"
                      : undefined
                  }
                  btnWithCrossTitle={
                    showModal === "delete"
                      ? "Удалить"
                      : "Закрыть без сохранения"
                  }
                  btnWithCrossOnClick={handleModalConfirm}
                  blueBtnTitle={
                    showModal === "delete" ? "Отмена" : "Сохранить и закрыть"
                  }
                  blueBtnOnClick={
                    showModal === "delete"
                      ? handleModalClose
                      : isValid
                      ? handleModalSave
                      : undefined
                  }
                  blueBtnDisabled={
                    showModal === "delete" ? undefined : !isValid
                  }
                  greyBtnTitle={showModal === "delete" ? undefined : "Отмена"}
                  greyBtnOnClick={
                    showModal === "delete" ? undefined : handleModalClose
                  }
                  btnWithCrossImg
                />
              )}
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default observer(StaffOneEditSettingForm);
