import styles from "./clearFieldButton.module.scss";
import { useField } from "formik";
import { ClearButton } from "./ClearButton";
import { classNames } from "shared/utils/helpers/classNames";

type ClearFieldButtonProps = {
  /**
   * Имя поля (для сброса значения)
   */
  name: string;
  /**
   * Является ли поле не активным
   */
  disabled: boolean;
  /**
   * Есть ли фокус на поле для отображения. Работает в связке со значением поля.
   * Если значения поля нет, то кнопка будет отображаться только если  на поле есть фокус.
   */
  isFocused: boolean;
  /**
   * Функция, отрабатывающая при клике на кнопку
   */
  onClick: () => void;
  /**
   * Стили, заданные в родителе
   */
  className?: string;
};

export const ClearFieldButton = ({
  name,
  disabled,
  isFocused,
  onClick,
  className
}: ClearFieldButtonProps) => {
  const [field] = useField(name);

  if (disabled || (!isFocused && !field.value?.length)) return;

  return field.value?.length ? (
    <div className={classNames(styles.container, {}, [className])}>
      <div className={styles.tooltip} data-tooltip="Очистить поле">
        <ClearButton name={name} className={className} onClick={onClick} />
      </div>
    </div>
  ) : (
    <ClearButton name={name} className={className} onClick={onClick} />
  );
};
