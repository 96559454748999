import styles from "./staffOneEditFormEmail.module.scss";
import { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import { useFormikContext } from "formik";

import addIcon from "shared/assets/images/mainIcons/iconAdd/iconAdd.svg";

import { Input } from "shared/ui/Inputs/Input";
import Checkbox from "shared/ui/Inputs/Checkbox";
import { ViewField } from "shared/ui/ViewField";
import ButtonsGroupForEdit from "shared/ui/ButtonsGroup/ButtonsGroupForEdit";
import Modal from "shared/ui/Modal";
import { useParams } from "react-router-dom";
import { diff } from "deep-object-diff";
import { IRecordsForEdit } from "stores/StaffModule/types/RecordsForEdit";
import { getKeys } from "shared/utils/helpers/getKeys";

type ModalAction = "delete" | "cancelAdd" | "editCancel" | null;

type StaffOneEditFormEmailProps = {
  disabled: boolean;
};

const StaffOneEditFormEmail = ({ disabled }: StaffOneEditFormEmailProps) => {
  const { staffOneEditStore } = useStores();
  const { id } = useParams<{ id: string }>();
  
  const [isAddEmail, setIsAddEmail] = useState(false);
  const [isEditingEmail, setIsEditingEmail] = useState(false);

  const [showModal, setShowModal] = useState<ModalAction>(null);

  const {
    values,
    initialValues,
    dirty,
    setFieldTouched,
    handleChange,
    handleBlur,
    setFieldValue,
    errors,
    isValid
  } = useFormikContext<IRecordsForEdit>();

  const [isEmail, setIsEmail] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);

  useEffect(() => {
    if (initialValues["email"] !== undefined) setIsEmail(true);
    if (initialValues["email_confirm"] !== undefined) setIsConfirm(true);
  }, [initialValues]);

  useEffect(() => {
    if (!dirty && isAddEmail) {
      setIsAddEmail(false);
      setIsEditingEmail(false);
    }
  }, [dirty]);

  const handleAddEmail = async (action: string) => {
    if (action === "submit") {
      if (values.email) {
        await staffOneEditStore.updateField(
          id,
          { email: values.email },
          "email"
        );
        setIsAddEmail(false);
        setIsEditingEmail(false);
        setIsEmail(true);
      }
    } else if (action === "cancel") {
      if (values.email) {
        setShowModal("cancelAdd");
      } else {
        setIsAddEmail(false);
        setIsEditingEmail(false);
      }
    }
  };

  const handleEditEmail = async (action: string) => {
    const initialEmail = initialValues.email;
    const currentEmail = values.email;
    const diffResult = diff({ email: initialEmail }, { email: currentEmail });
    const hasChanges = getKeys(diffResult).length > 0;

    switch (action) {
      case "submit":
        if (hasChanges) {
          await staffOneEditStore.updateField(
            id,
            { email: currentEmail },
            "email"
          );
        }
        setIsEditingEmail(false);
        break;
      case "delete":
        setShowModal("delete");
        break;
      case "cancel":
        if (hasChanges) {
          setShowModal("editCancel");
        } else {
          setIsEditingEmail(false);
        }
        break;
      default:
        break;
    }
  };

  const handleDeleteEmail = async () => {
    await staffOneEditStore.updateField(id, { email: null }, "email");
    setFieldValue("email", "");
    setIsEmail(false);
    setIsEditingEmail(false);
    setShowModal(null);
  };

  const handleModalConfirm = async () => {
    if (showModal === "delete") {
      await handleDeleteEmail();
    } else if (showModal === "cancelAdd") {
      setIsAddEmail(false);
      setIsEditingEmail(false);
      setFieldValue("email", initialValues.email);
    } else if (showModal === "editCancel") {
      setIsEditingEmail(false);
      setFieldValue("email", initialValues.email);
    }
    setShowModal(null);
  };

  const handleModalClose = () => {
    setShowModal(null);
  };

  const handleModalSave = async () => {
    if (showModal === "editCancel") {
      await handleEditEmail("submit");
    } else if (showModal === "cancelAdd") {
      await handleAddEmail("submit");
    }
    setShowModal(null);
  };

  return !staffOneEditStore.isLoadingEmail ? (
    <div className={`${styles.form} ${disabled ? styles.disabled : ""}`}>
      <div className={styles.subtitle}>Электронная почта</div>
      <div className={styles.containerForm}>
        {values["email"] || isEditingEmail || isAddEmail ? (
          <ul
            className={
              isEditingEmail || isAddEmail
                ? `${styles.confirmRowEmail} ${styles.confirmRowEmail_selected}`
                : styles.confirmRowEmail
            }
          >
            {isAddEmail ? null : isConfirm ? (
              <li className={styles.confirmRowEmail__confirm}>
                <label htmlFor="email_confirm">
                  {staffOneEditStore.staffTableCols["email_confirm"]["title"]}
                </label>
                <Checkbox
                  name="custom.email_confirm"
                  id="email_confirm"
                  value={values["email_confirm"]}
                  disabled
                />
              </li>
            ) : null}
            {isEmail || isAddEmail ? (
              <>
                <li className={styles.confirmRowEmail__order}>
                  <ViewField
                    title="№"
                    value="1"
                    className={{ container: styles.confirmRowEmailBorder }}
                  />
                </li>
                <li className={styles.confirmRowEmail__email}>
                  <Input
                    name="email"
                    label={staffOneEditStore.staffTableCols["email"]["title"]}
                    onChange={(e) => {
                      handleChange(e);
                      setFieldTouched("email");
                    }}
                    onBlur={handleBlur}
                    disabled={!isEditingEmail && !isAddEmail}
                  />
                </li>
                <li>
                  {isEditingEmail || isAddEmail ? (
                    <ButtonsGroupForEdit
                      id="email"
                      idx={0}
                      btns={[
                        {
                          action: "submit",
                          type: "button",
                          icon: "bigcheck",
                          color:
                            errors.email || !dirty ? "bw-gray3" : "blue-lazure",
                          disabled: Boolean(errors.email) || !dirty
                        },
                        {
                          action: "cancel",
                          type: "button",
                          icon: "iconclose",
                          color: "blue-lazure",
                          disabled: false
                        },
                        {
                          action: "delete",
                          type: "button",
                          icon: "iconbasket",
                          color: "bw-gray5",
                          disabled: false
                        }
                      ]}
                      onClick={(action) => {
                        if (isAddEmail) {
                          handleAddEmail(action);
                        } else {
                          handleEditEmail(action);
                        }
                      }}
                    />
                  ) : (
                    <ButtonsGroupForEdit
                      id="email"
                      idx={0}
                      btns={[
                        {
                          action: "edit",
                          type: "button",
                          icon: "iconedit",
                          color: "bw-gray5",
                          disabled: false
                        },
                        {
                          action: "delete",
                          type: "button",
                          icon: "iconbasket",
                          color: "bw-gray5",
                          disabled: false
                        }
                      ]}
                      onClick={(action) => {
                        if (
                          isAddEmail &&
                          (action === "edit" || action === "delete")
                        ) {
                          setShowModal("cancelAdd");
                        } else if (action === "edit") {
                          setIsEditingEmail(true);
                        } else if (action === "delete") {
                          setShowModal("delete");
                        }
                      }}
                    />
                  )}
                </li>
              </>
            ) : null}
          </ul>
        ) : (
          <button
            id="StaffOneEditEmail_addEmailFormButton"
            type="button"
            className={styles.buttonAddNumber}
            onClick={() => {
              setIsAddEmail(true);
              setIsEditingEmail(true);
            }}
          >
            <p className={styles.buttonAddNumber__text}>Добавить</p>
            <img
              src={addIcon}
              className={styles.buttonAddNumber__icon}
              alt="Добавить"
            />
          </button>
        )}
      </div>
      {showModal && (
        <Modal
          type={
            showModal === "delete"
              ? "clarification"
              : "clarificationForFormWindows"
          }
          show
          onHide={handleModalClose}
          title={
            showModal === "delete"
              ? "Вы уверены, что хотите удалить электронную почту?"
              : "Внесены изменения."
          }
          subtitle={
            showModal === "editCancel" || showModal === "cancelAdd"
              ? "Вы уверены, что хотите закрыть без сохранения изменений?"
              : undefined
          }
          btnWithCrossTitle={
            showModal === "delete" ? "Удалить" : "Закрыть без сохранения"
          }
          btnWithCrossOnClick={handleModalConfirm}
          blueBtnTitle={
            showModal === "delete" ? "Отмена" : "Сохранить и закрыть"
          }
          blueBtnOnClick={
            showModal === "delete"
              ? handleModalClose
              : isValid
              ? handleModalSave
              : undefined
          }
          blueBtnDisabled={showModal !== "delete" && !isValid}
          greyBtnTitle={showModal === "delete" ? undefined : "Отмена"}
          greyBtnOnClick={showModal === "delete" ? undefined : handleModalClose}
          btnWithCrossImg
        />
      )}
    </div>
  ) : (
    <p className={styles.loading}>Пожалуйста, подождите...</p>
  );
};

export default observer(StaffOneEditFormEmail);
