import styles from "./awardsFields.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores";
import { fileUrl } from "stores/utils/consts";
import { classNames } from "shared/utils/helpers/classNames";
import { getFormattedDate } from "shared/utils/helpers/getFormattedDate";
import StatusIcon from "shared/ui/StatusIcon";
import { Awards } from "stores/StaffModule/types/Awards";

type AwardsFieldsProps = {
  awards: Awards;
  field: string;
};

const AwardsFields = ({ awards, field }: AwardsFieldsProps) => {
  const { staffOneAwardsStore } = useStores();

  const isOpenedField =
    (!staffOneAwardsStore.awardsArray?.includes(field) &&
      !staffOneAwardsStore.isFocusSearch) ||
    (staffOneAwardsStore.isFocusSearch &&
      staffOneAwardsStore.awardsFieldsFoundDuringSearch.includes(field));

  const toggleOneFieldShowing = () => {
    if (
      !staffOneAwardsStore.isFocusSearch &&
      !staffOneAwardsStore.searchValue
    ) {
      staffOneAwardsStore.changeOpenedAwards(
        !isOpenedField ? "delete" : "add",
        [field]
      );
    }
    return;
  };

  return (
    <div className={styles.dashedLine}>
      <div className={styles.widgetHeader}>
        <div
          id={`AwardsFields_toggleOneFieldShowing_${field}`}
          onClick={toggleOneFieldShowing}
          className={classNames(styles.fieldButton, {
            [styles.fieldButtonNonActive]:
              staffOneAwardsStore.isFocusSearch ||
              staffOneAwardsStore.searchValue,
            [styles.fieldButtonClosed]: !isOpenedField,
            [styles.fieldButtonOpened]: isOpenedField
          })}
        >
          <StatusIcon
            icon="iconcollapsestroke"
            color={!isOpenedField ? "bw-gray6" : "blue-lazure"}
          />
          <p>{field}</p>
          <div className={styles.gradientLine} />
        </div>
      </div>
      {isOpenedField ? (
        <div className={styles.field}>
          {Object.keys(awards).length ? (
            Object.values(awards)
              .sort((a, b) => (a.time_create > b.time_create ? 1 : -1))
              .map((award) => {
                return (
                  <div key={award.id} className={styles.award}>
                    <div className={styles.dataText}>
                      {getFormattedDate(award.award_start)}
                    </div>
                    {award.img_src !== -1 ? (
                      <img
                        src={fileUrl + award.img_src}
                        alt={award.comment}
                        className={styles.img}
                      />
                    ) : (
                      ""
                    )}
                    <h3 className={styles.title}>{award.type_title}</h3>
                  </div>
                );
              })
          ) : (
            <div className={styles.withoutAwards}>
              {`У сотрудника нет наград за ${field}`}
            </div>
          )}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default observer(AwardsFields);
