// УДАЛИТЬ ПРАВИЛО ПРИ ДАЛЬНЕЙШЕЙ РАЗРАБОТКЕ
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { ReactComponent as IconRotate } from "shared/assets/images/mainIcons/iconRotateWithoutFill.svg";
import { ReactComponent as IconMirror } from "shared/assets/images/mainIcons/iconMirrorWithoutStroke.svg";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import styles from "./staffOneEditPhotoAltCropper.module.scss";
import { classNames } from "shared/utils/helpers/classNames";

type StaffOneEditPhotoAltCropperProps = {
  file: string;
  galleryState?: string;
  imageIndex?: number;
};
export const StaffOneEditPhotoAltCropper = forwardRef(
  (
    { file, galleryState, imageIndex }: StaffOneEditPhotoAltCropperProps,
    ref
  ) => {
    const [cropper, setCropper] = useState<Cropper>();
    const [flipX, setFlipX] = useState(1);
    const [inputValue, setInputValue] = useState(100);

    useEffect(() => {
      resetChanges();
    }, [imageIndex]);

    const getCropImage = () => {
      if (typeof cropper !== "undefined") {
        return cropper.getCroppedCanvas().toDataURL("image/jpeg", 0.75);
      }
    };

    useImperativeHandle(ref, () => ({
      getCropImage
    }));

    const getFlipImage = () => {
      if (typeof cropper !== "undefined") {
        const newFlipX = flipX * -1;
        cropper.scale(newFlipX, 1);
        setFlipX(newFlipX);
      }
    };

    const getRotateImage = () => {
      if (typeof cropper !== "undefined") {
        cropper.rotate(90);
      }
    };

    const getZoomIn = () => {
      if (typeof cropper !== "undefined") {
        cropper.zoom(0.1);
        setInputValue(inputValue + 10);
      }
    };

    const getZoomOut = () => {
      if (typeof cropper !== "undefined") {
        cropper.zoom(-0.1);
        setInputValue(inputValue - 10);
      }
    };

    const resetChanges = () => {
      if (typeof cropper !== "undefined") {
        cropper.reset();
        setInputValue(100);
      }
    };

    return (
      <>
        {galleryState !== "viewing" ? (
          <Cropper
            className={styles.cropper}
            dragMode={"move"}
            src={file}
            background={false}
            minCropBoxHeight={230}
            minCropBoxWidth={230}
            viewMode={1}
            autoCropArea={0.8}
            autoCrop={true}
            onInitialized={(instance) => {
              setCropper(instance);
            }}
            guides
            checkCrossOrigin={false}
          />
        ) : (
          <img src={file} className={styles.photo} />
        )}

        <div
          className={`${styles.buttonsBox}
            ${
              galleryState === "viewing"
                ? styles.buttonsBox
                : styles.buttonsBox__small
            }`}
        >
          {galleryState !== "viewing" ? (
            <>
              <button
                onClick={getRotateImage}
                className={classNames(styles.iconRotate, {}, [styles.tooltip])}
                type="button"
                id="StaffOneEditPhotoAltCropper_rotateImage"
                data-tooltip="Повернуть"
              >
                <IconRotate alt="Rotate" />
              </button>

              <button
                onClick={getFlipImage}
                className={classNames(styles.iconMirror, {}, [styles.tooltip])}
                type="button"
                id="StaffOneEditPhotoAltCropper_flipImage"
                data-tooltip="Отзеркалить"
              >
                <IconMirror alt="Crop" />
              </button>
            </>
          ) : (
            <>
              {/* РАСКОММЕНТИРОВАТЬ ПРИ ДАЛЬНЕЙШЕЙ РАЗОРАБОТКЕ */}
              {/* 
                <button
                  onClick={getZoomIn}
                  className={classNames(styles.iconPlus, {}, [styles.tooltip])}
                  type="button"
                  id="StaffOneEditPhotoAltCropper_zoomInImage"
                  data-tooltip="Увеличить"
                >
                  <IconPlus alt="ZoomIn" />
                </button>

              <button
                  onClick={getZoomOut}
                  className={classNames(styles.iconMinus, {}, [styles.tooltip])}
                  type="button"
                  id="StaffOneEditPhotoAltCropper_zoomOutImage"
                  disabled={inputValue === 70}
                  data-tooltip="Уменьшить"
                >
                  <IconMinus alt="ZoomOut" />
                </button>
              <span className={styles.scale}>{inputValue}%</span>

                <button
                  onClick={() => (inputValue === 100 ? "" : resetChanges())}
                  className={classNames(styles.iconReset, {}, [styles.tooltip])}
                  type="button"
                  id="StaffOneEditPhotoAltCropper_reset"
                  disabled={!(inputValue > 100 || inputValue < 100)}
                  data-tooltip="Сбросить изменения"
                >
                  {" "}
                  Сбросить
                </button> */}
            </>
          )}
        </div>
      </>
    );
  }
);

StaffOneEditPhotoAltCropper.displayName = "StaffOneEditPhotoAltCropper";
export default StaffOneEditPhotoAltCropper;
