import styles from "./filterMain.module.scss";
import { cloneElement, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "stores";

import LoadedComponent from "widgets/LoadedComponent";
import { ReactComponent as IconClose } from "shared/assets/images/mainIcons/iconsClose/iconCloseWithoutFill.svg";
import { ReactComponent as IconBasket } from "shared/assets/images/mainIcons/iconBasket/iconBasketWithoutFill.svg";
import { ReactComponent as IconQuestionSmall } from "shared/assets/images/mainIcons/iconQuestionSmall/iconQuestionSmallWithoutStroke.svg";
import { ReactComponent as IconRightBlue } from "shared/assets/images/mainIcons/iconRightBlue.svg";
import FilterMainCols from "./FilterMainCols/index";
import Modal from "shared/ui/Modal";
import { Input } from "shared/ui/Inputs/Input";
import Select from "shared/ui/Inputs/Select";
import DatePickerField from "shared/ui/Inputs/DatePickerField";

import { useFormikContext } from "formik";

import { cloneDeep } from "lodash";
import { FilterType } from "stores/utils/types/FilterType";
import { Col } from "stores/utils/types/Col";
import { useSearchParams } from "react-router-dom";
import ButtonsGroupForSettings from "shared/ui/ButtonsGroup/ButtonsGroupForSettings";
import { diff } from "deep-object-diff";
import FilterMainWindow from "./FilterMainWindow";
import DateRangePicker from "shared/ui/Inputs/DateRangePicker";
import { format } from "date-fns";
import Switch from "shared/ui/Inputs/Switch";
import { Button, ButtonTheme } from "shared/ui/Button";
import { Chips, ChipsVariation } from "shared/ui/Chips";

type FilterMainProps = {
  isLoading: boolean;
  isLoadingForFilters: boolean;
  selectedFilter: string;
  downloadedFilter: string;
  selectMulti: string[];

  filters: {
    [key: string]: FilterType;
  };
  currentCols: string[];
  allCols: { [key: string]: Col };
  cols: { [key: string]: Col };
  params: { [key: string]: Col };

  setSearchValue: (value: string) => void;
  setFilters: (action: string, id: string, values: FilterType) => Promise<void>;
  setSelectedFilter: (value: string) => void;
  getDataWithFilter: (filter: FilterType) => void;
  setQueryParams?: (value: { [key: string]: string }) => void;
  setListToInitialPosition: () => void;

  valueName?: string;

  openedListName: string;

  changeValue: (
    key: string,
    value: string | number | string[] | FilterType["filter"]
  ) => void;
  setOpenedListName: (value: string) => void;
  defaultFields: Record<string, string | string[]>;
  dateRangeFields: Record<string, string[]>;
  customFilterMain?: JSX.Element;
  selectsWithLoading?: string[];
  withoutSavedFilter?: boolean;
  pageSelectsInfo?: Record<
    string,
    {
      page: number;
      prevPage: number;
      maxPage: number;
      searchValue: string;
      isLoading: boolean;
      setSearchValue: (value: string) => void;
      setPage: (value: number) => void;
      getList: () => void;
    }
  >;
};

const FilterMain = ({
  isLoading,
  isLoadingForFilters,
  filters,
  selectedFilter,
  downloadedFilter,
  setFilters,
  setSearchValue,

  setQueryParams,
  cols,
  params,
  currentCols,
  allCols,
  setSelectedFilter,
  getDataWithFilter,
  selectMulti,
  valueName,

  openedListName,
  changeValue,
  setOpenedListName,
  setListToInitialPosition,
  defaultFields,
  dateRangeFields,
  customFilterMain,
  selectsWithLoading,
  withoutSavedFilter,
  pageSelectsInfo
}: FilterMainProps) => {
  const { filterStore, menuStore } = useStores();
  const [searchParams, setSearchParams] = useSearchParams();

  const {
    handleChange,
    handleSubmit,
    dirty,
    isValid,
    initialValues,
    values,
    handleReset,
    setFieldValue,
    setValues
  } = useFormikContext<FilterType>();

  // Если в url есть параметры фильтрации, то добавляем их в values Formik'a расширенного поиска
  // с помощью функции changeValue
  useEffect(() => {
    // сюда будем записывать итоговый объект с параметрами фильтрации
    const queryParams:
      | {
          [key: string]: string | number;
        }
      | { [key: string]: string[] } = {};
    if (
      searchParams.toString() &&
      searchParams.toString().includes("filter") &&
      !isLoading &&
      !downloadedFilter
    ) {
      // сюда записываем все что есть в url из параметров фильтрации
      const filterParams: { [key: string]: string } = {};
      for (const [key, value] of searchParams.entries()) {
        filterParams[key] = value;
      }

      // проебразуем параметры фильтрации из url в вид, подходящий для values Formik'a
      Object.entries(filterParams).forEach(([key, value]) => {
        if (key.includes("filter")) {
          if (key.match(/[0-9.,:]/)) {
            const valideName = key.split("[").slice(0, -1).join("[");
            if ((queryParams[valideName] as string)?.length) {
              queryParams[valideName] = [
                ...(queryParams[valideName] as string[]),
                value
              ];
            } else {
              queryParams[valideName] = [value];
            }
          } else {
            queryParams[key] = value;
          }
        }
      });

      setTimeout(() => {
        Object.entries(queryParams).length &&
          Object.entries(queryParams).forEach(([key, value]) => {
            changeValue(key, value);
          });
      }, 100);
    }
  }, [isLoading]);

  useEffect(() => {
    const difference: Partial<FilterType> = diff(values, initialValues);

    Object.keys(difference).forEach((key) => {
      if (key.includes("isIntervalField") || key === "cols") {
        delete difference[key];
      }
    });

    Object.values(difference).length
      ? filterStore.setIsDiff(true)
      : filterStore.setIsDiff(false);
  }, [values]);

  useEffect(() => {
    if (filterStore.isDownloadedFilterSelected) {
      handleReset();
      filterStore.setIsDownloadedFilterSelected(false);
    }
  }, [filterStore.isDownloadedFilterSelected]);

  useEffect(() => {
    if (isLoading && filterStore.searchInputValue) {
      setValues(initialValues);
    }
  }, [isLoading, filterStore.searchInputValue]);

  useEffect(() => {
    if (
      filterStore.isOpenedColumnsOptions &&
      filters?.[downloadedFilter]?.["general"]
    ) {
      filterStore.setIsOpenedColumnsOptions(false);
    }
  }, [
    filterStore.isOpenedColumnsOptions,
    filters?.[downloadedFilter]?.["general"]
  ]);

  const valueField = valueName || "newname";

  const changeOpenedWindows = (name: string) => {
    openedListName === name ? setOpenedListName("") : setOpenedListName(name);
  };

  // задаем пропсы для кастомного фильтр
  const propsForCustom = {
    selectedFilter: selectedFilter,
    openedListName: openedListName,
    valueField: valueField,
    values: values,
    params: params,
    selectMulti: selectMulti,
    dateRangeFields: dateRangeFields,
    changeValue: changeValue,
    changeOpenedWindows: changeOpenedWindows,
    dictForArray: filterStore.dictForArrayValuesFilter,
    setDictForArray: filterStore.setDictForArrayValuesFilter,
    selectsWithLoading: selectsWithLoading,
    pageSelectsInfo: pageSelectsInfo
  };

  // создаем кастомный фильтр на основе customFilterMain и propsForCustom
  const customFilter =
    customFilterMain && cloneElement(customFilterMain, propsForCustom);
  return (
    <div
      className={`${styles.main} ${
        menuStore.isMenuShown
          ? styles.main_withOpenedMenu
          : styles.main_withClosedMenu
      }`}
    >
      <LoadedComponent isLoading={isLoadingForFilters}>
        <>
          <div
            className={
              !filterStore.isOpenedColumnsOptions
                ? styles.cols
                : styles.columsParamsBlock
            }
          >
            {(filterStore.isOpenAdvancedSearch ||
              filterStore.isOpenedSavedFilterOptions) &&
            values?.filter &&
            Object.keys(values.filter).length ? (
              <div className={styles.unit}>
                {!customFilterMain
                  ? Object.keys(values.filter).map((key) => {
                      const field = params[key];

                      if (field?.directory) {
                        const dict = field.directory;

                        if (selectMulti && selectMulti.includes(key)) {
                          return (
                            // компонент вывода нескольких значений параметров/колонок
                            <FilterMainCols
                              key={key}
                              array={[...Object.values(values["filter"][key])]}
                              directory={dict}
                              deleteItem={(value) => {
                                const newArray = (
                                  Object.values(
                                    values["filter"][key]
                                  ) as string[]
                                )?.filter((item) => item !== value);
                                changeValue(`filter.${key}`, newArray);
                              }}
                              deleteAllItem={() =>
                                changeValue(`filter.${key}`, [])
                              }
                              updateItems={(value) => {
                                changeValue(`filter.${key}`, value as string[]);
                              }}
                              isMenuOpened={openedListName === `filter.${key}`}
                              setIsMenuOpened={() => {
                                changeOpenedWindows(`filter.${key}`);
                              }}
                              placeholder={field.title}
                              label={field.title}
                              selectedFilter={selectedFilter}
                              isSearchWithPagination={
                                selectsWithLoading?.includes(key) ? true : false
                              }
                              pageSelectsInfo={pageSelectsInfo}
                              field={key}
                              dictForArray={
                                filterStore.dictForArrayValuesFilter
                              }
                              setDictForArray={
                                filterStore.setDictForArrayValuesFilter
                              }
                            />
                          );
                        } else {
                          return (
                            <Select
                              key={key}
                              name={`filter.${key}`}
                              title={field.title}
                              label={
                                values.filter[key] && values.filter[key] !== -1
                                  ? dict[values.filter[key] as string]
                                    ? dict[values.filter[key] as string].title
                                    : (values.filter[key] as string)
                                  : null
                              }
                              options={dict}
                              onClick={(value) => {
                                changeValue(
                                  `filter.${key}`,
                                  value[valueField] as string
                                );
                              }}
                              isFloating
                              valueName={valueField}
                            />
                          );
                        }
                      }

                      if (
                        field?.type === "date" ||
                        field?.type === "timestamp"
                      ) {
                        if (dateRangeFields && key in dateRangeFields) {
                          return (
                            <div
                              key={key}
                              className={styles.dateRangeContainer}
                            >
                              <div>
                                {!values[`isIntervalField_${key}`] ? (
                                  <DatePickerField
                                    name={`filter.${dateRangeFields[key][0]}`}
                                    title={field.title}
                                    isCalendarOpened={openedListName === key}
                                    setIsCalendarOpened={() => {
                                      changeOpenedWindows(key);
                                    }}
                                    onBlur={() => {
                                      changeOpenedWindows(null);
                                      if (
                                        new Date(
                                          values["filter"][
                                            `${dateRangeFields[key][0]}`
                                          ] as string
                                        ).valueOf() > new Date().valueOf()
                                      ) {
                                        setFieldValue(
                                          `filter.${dateRangeFields[key][1]}`,
                                          values["filter"][
                                            `${dateRangeFields[key][0]}`
                                          ]
                                        );
                                      } else {
                                        setFieldValue(
                                          `filter.${dateRangeFields[key][1]}`,
                                          format(new Date(), "yyyy-MM-dd")
                                        );
                                      }
                                    }}
                                  />
                                ) : (
                                  <DateRangePicker
                                    monthsShown={2}
                                    name={`filter.${dateRangeFields[key][0]}`}
                                    title={field.title}
                                    isCalendarOpened={
                                      openedListName ===
                                      `filter.${dateRangeFields[key][0]}`
                                    }
                                    setIsCalendarOpened={() => {
                                      changeOpenedWindows(
                                        `filter.${dateRangeFields[key][0]}`
                                      );
                                    }}
                                    placeholderVisible
                                    siblingDateName={`filter.${dateRangeFields[key][1]}`}
                                    isForFilter
                                    valueStartDate={
                                      values.filter[
                                        dateRangeFields[key][0]
                                      ] as string
                                    }
                                    valueEndDate={
                                      values.filter[
                                        dateRangeFields[key][1]
                                      ] as string
                                    }
                                  />
                                )}
                              </div>
                              <label className={styles.switch}>
                                <Switch
                                  name={`isIntervalField_${key}`}
                                  onChange={(e) => {
                                    if (
                                      !e.target.checked &&
                                      values.filter[dateRangeFields[key][1]]
                                    ) {
                                      setFieldValue(
                                        `filter.${dateRangeFields[key][1]}`,
                                        format(new Date(), "yyyy-MM-dd")
                                      );
                                    }
                                  }}
                                />
                                Указать интервал
                              </label>
                            </div>
                          );
                        } else {
                          return (
                            <DatePickerField
                              key={key}
                              name={`filter.${key}`}
                              title={field.title}
                              isCalendarOpened={openedListName === key}
                              setIsCalendarOpened={() => {
                                changeOpenedWindows(key);
                              }}
                              onBlur={() => {
                                changeOpenedWindows(null);
                              }}
                            />
                          );
                        }
                      }

                      if (
                        dateRangeFields &&
                        Object.values(dateRangeFields).flat().includes(key)
                      ) {
                        return;
                      }

                      if (field?.type === "bool") {
                        return (
                          <div className={styles.input} key={key}>
                            <Select
                              name={`filter.${key}`}
                              title={field.title}
                              isFloating
                              options={{
                                1: { newname: "1", title: "Да" },
                                0: { newname: "0", title: "Нет" }
                              }}
                              onClick={(option) => {
                                changeValue(
                                  `filter.${key}`,
                                  option.newname as string
                                );
                              }}
                            />
                          </div>
                        );
                      }

                      return (
                        <div className={styles.input} key={key}>
                          <Input
                            name={`filter.${key}`}
                            onChange={(e) => {
                              handleChange(e);
                              changeValue(`filter.${key}`, e.target.value);
                            }}
                            label={field?.title}
                          />
                        </div>
                      );
                    })
                  : customFilter}
              </div>
            ) : (
              ""
            )}
            {filterStore.isOpenAdvancedSearch ||
            filterStore.isOpenedSavedFilterOptions ? (
              <div className={styles.unit}>
                <Button
                  theme={ButtonTheme.CLEAR}
                  id="FilterMain_allParamsBtn"
                  onClick={() => changeOpenedWindows("params")}
                >
                  Все параметры
                  <IconRightBlue />
                </Button>

                {openedListName === "params" ? (
                  // компонент редактирования в модальном окне настроек параметров
                  <>
                    <FilterMainWindow
                      title="Параметры"
                      subtitle={`Фильтр: ${
                        filters?.[selectedFilter]
                          ? filters[selectedFilter]["title"]
                          : "Новый"
                      }`}
                      setIsMenuOpened={() => changeOpenedWindows("params")}
                      isMultiSelect
                      valuesArray={
                        values?.filter &&
                        Object.keys(values.filter).length &&
                        filterStore.creatingNewFilter(
                          currentCols,
                          filterStore.getDefaultArray(allCols, cols),
                          defaultFields
                        ).filter
                          ? Object.keys(values.filter).filter((key) =>
                              !filterStore.isOpenedSavedFilterOptions
                                ? !(
                                    key in
                                    filterStore.creatingNewFilter(
                                      currentCols,
                                      filterStore.getDefaultArray(
                                        allCols,
                                        cols
                                      ),
                                      defaultFields
                                    ).filter
                                  ) && key in params
                                : key in params
                            )
                          : []
                      }
                      defaultArray={
                        !filterStore.isOpenedSavedFilterOptions &&
                        filterStore.creatingNewFilter(
                          currentCols,
                          filterStore.getDefaultArray(allCols, cols),
                          defaultFields
                        ).filter &&
                        Object.keys(
                          filterStore.creatingNewFilter(
                            currentCols,
                            filterStore.getDefaultArray(allCols, cols),
                            defaultFields
                          ).filter
                        )
                      }
                      directory={params}
                      updateItems={(value) => {
                        const object: FilterType["filter"] = {};
                        value.forEach((title) => {
                          if (
                            values.filter[title] ||
                            (values.filter[title] as string[])?.length
                          ) {
                            object[title] = values.filter[title];
                          } else object[title] = "";
                        });
                        changeValue("filter", object);
                      }}
                    />
                  </>
                ) : (
                  ""
                )}
              </div>
            ) : (
              ""
            )}
            {filterStore.isOpenedColumnsOptions &&
            !filters?.[downloadedFilter]?.["general"] ? (
              <div className={styles.unit}>
                <div className={styles.title}>
                  Показать столбцы
                  <div
                    className={styles.tooltip}
                    data-tooltip="Настройка отображения столбцов таблицы и их порядок (будет соответствовать заданной здесь очередности названий столбцов слева направо)"
                  >
                    <IconQuestionSmall className={styles.iconQuestion} />
                  </div>
                </div>

                <FilterMainCols
                  array={values.cols || []}
                  defaultArray={filterStore.getDefaultArray(allCols, cols)}
                  directory={allCols}
                  deleteItem={(value) => {
                    const newArray = values.cols.filter(
                      (item) => item !== value
                    );
                    changeValue("cols", newArray);
                  }}
                  deleteAllItem={() => changeValue("cols", [])}
                  updateItems={(value) => {
                    changeValue("cols", value);
                  }}
                  setIsMenuOpened={() => changeOpenedWindows("cols")}
                  isMenuOpened={openedListName === "cols"}
                  placeholder="Столбцы"
                  label="Столбцы"
                  error
                  selectedFilter={selectedFilter}
                  isColumns
                />

                <div className={styles.unit}>
                  <div className={styles.title}>
                    Настройка сортировки
                    <div
                      className={styles.tooltip}
                      data-tooltip="Настройка сортировки записей таблицы по выбранному критерию и в заданном порядке"
                    >
                      <IconQuestionSmall className={styles.iconQuestion} />
                    </div>
                  </div>
                  <div className={styles.formRow}>
                    <div className={styles.miniInput}>
                      <Select
                        required
                        name="order"
                        options={cols as Col["directory"]}
                        onClick={(value) => {
                          changeValue("order", value[valueField] as string);
                          changeOpenedWindows("order");
                        }}
                        title="Сортировать содержимое столбцов"
                        label={cols[values.order]?.title}
                        isFloating
                      />
                    </div>
                    <Chips
                      variation={ChipsVariation.FILTER}
                      className={
                        values.ordered === "ASC"
                          ? styles.grayBtnSelected
                          : styles.grayBtn
                      }
                      onClick={() => {
                        changeValue("ordered", "ASC");
                      }}
                      id="filtermain_changeOrderASC"
                      title="По возрастанию"
                    />
                    <Chips
                      variation={ChipsVariation.FILTER}
                      onClick={() => {
                        changeValue("ordered", "DESC");
                      }}
                      className={
                        values.ordered === "DESC"
                          ? styles.grayBtnSelected
                          : styles.grayBtn
                      }
                      id="filtermain_changeOrderDESC"
                      title="По убыванию"
                    />
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
          <div className={styles.unit}>
            <div
              className={`${styles.btnsGroup} ${
                filterStore.isOpenAdvancedSearch
                  ? styles.btnsGroup_lastBlueBtn
                  : ""
              }`}
            >
              {/* КНОПКИ ДЛЯ РАСШИРЕННОГО ПОИСКА */}
              {filterStore.isOpenAdvancedSearch ? (
                <ButtonsGroupForSettings
                  saveBtnTitle="Найти"
                  saveBtnOnClick={() => {
                    filterStore.setSearchInputValue("");
                    setSearchValue && setSearchValue("");
                    setOpenedListName("");
                    getDataWithFilter(values);
                    setListToInitialPosition();
                  }}
                  saveBtnDisabled={!isValid || !dirty}
                  addBtnTitle="Сбросить"
                  addBtnImg={<IconClose />}
                  addBtnOnClick={() => {
                    setOpenedListName("");
                    handleReset();
                    filterStore.setIsDiff(false);
                    if (values.id !== "new") {
                      filterStore.setCurrentFilter(
                        cloneDeep(filters[selectedFilter])
                      );
                    } else {
                      filterStore.setCurrentFilter(
                        filterStore.creatingNewFilter(
                          currentCols,
                          filterStore.getDefaultArray(allCols, cols),
                          defaultFields
                        )
                      );
                      (withoutSavedFilter || downloadedFilter === "new") &&
                        filters &&
                        Object.values(filters).forEach((filter) => {
                          if (filter["default"]) {
                            getDataWithFilter(filter);
                            setListToInitialPosition();
                          }
                        });
                      if (!filters)
                        getDataWithFilter(filterStore.currentFilter);
                    }

                    if (values.general && filterStore.isGeneralFilterChanged) {
                      filterStore.setIsGeneralFilterChanged(false);
                      Object.values(filters).forEach((filter) => {
                        if (filter["default"]) {
                          getDataWithFilter(filter);
                          setListToInitialPosition();
                        }
                      });
                    }
                  }}
                  addBtnDisabled={!dirty}
                  cancelBtnTitle="Сохранить фильтр"
                  cancelBtnOnClick={() => filterStore.setShowSaveModal(true)}
                  cancelBtnDisabled={withoutSavedFilter || !isValid || !dirty}
                />
              ) : (
                ""
              )}
              {/* КНОПКИ ДЛЯ ИЗМЕНЕНИЯ КОЛОНОК */}
              {filterStore.isOpenedColumnsOptions ? (
                <ButtonsGroupForSettings
                  saveBtnTitle="Применить настройки"
                  saveBtnOnClick={() => {
                    if (
                      !initialValues.general &&
                      downloadedFilter !== "new" &&
                      downloadedFilter
                    ) {
                      setOpenedListName("");
                      handleSubmit();
                      setSelectedFilter(values.id);
                    } else if (
                      !downloadedFilter ||
                      downloadedFilter === "new"
                    ) {
                      filterStore.setSearchInputValue("");
                      setSearchValue && setSearchValue("");
                      setOpenedListName("");
                      getDataWithFilter(values);
                      setListToInitialPosition();
                      initialValues.general &&
                        filterStore.setIsGeneralFilterChanged(true);
                    }
                  }}
                  saveBtnDisabled={!isValid || !dirty || !values.cols?.length}
                  addBtnTitle="Отмена"
                  addBtnImg={<IconClose />}
                  addBtnOnClick={() => {
                    setOpenedListName("");
                    handleReset();
                    filterStore.setIsDiff(false);
                    if (values.id !== "new") {
                      filterStore.setCurrentFilter(
                        cloneDeep(filters[selectedFilter])
                      );
                    } else {
                      filterStore.setCurrentFilter(
                        filterStore.creatingNewFilter(
                          currentCols,
                          filterStore.getDefaultArray(allCols, cols),
                          defaultFields
                        )
                      );
                      if (!filters)
                        getDataWithFilter(filterStore.currentFilter);
                    }

                    if (values.general && filterStore.isGeneralFilterChanged) {
                      filterStore.setIsGeneralFilterChanged(false);
                      Object.values(filters).forEach((filter) => {
                        if (filter["default"]) {
                          getDataWithFilter(filter);
                          setListToInitialPosition();
                        }
                      });
                    }
                  }}
                  addBtnDisabled={!dirty}
                />
              ) : (
                ""
              )}
              {/* КНОПКИ ДЛЯ ИЗМЕНЕНИЯ СОХРАНЕННЫХ ФИЛЬТРОВ */}
              {filterStore.isOpenedSavedFilterOptions ? (
                <ButtonsGroupForSettings
                  saveBtnTitle="Обновить"
                  saveBtnOnClick={() => {
                    if (
                      !initialValues.general &&
                      downloadedFilter !== "new" &&
                      downloadedFilter
                    ) {
                      setOpenedListName("");
                      handleSubmit();
                      setSelectedFilter(values.id);
                    }
                  }}
                  saveBtnDisabled={!isValid || !dirty}
                  addBtnTitle="Отменить"
                  addBtnImg={<IconClose />}
                  addBtnOnClick={() => {
                    setOpenedListName("");
                    handleReset();
                    filterStore.setIsDiff(false);
                    if (values.id !== "new") {
                      filterStore.setCurrentFilter(
                        cloneDeep(filters[selectedFilter])
                      );
                    }
                  }}
                  addBtnDisabled={!dirty}
                  cancelBtnTitle="Удалить фильтр"
                  cancelBtnOnClick={() => filterStore.setShowDeleteModal(true)}
                  cancelBtnImg={<IconBasket />}
                />
              ) : (
                ""
              )}
            </div>
          </div>
          {filterStore.showSaveModal ? (
            // компонент редактирования и сохранения в модальном окне настроек нового фильтра
            <FilterMainWindow
              title="Сохранение фильтра"
              setIsMenuOpened={() => filterStore.setShowSaveModal(false)}
              valuesSaveFilter={values}
              directory={params}
              setNewFilter={(value) => {
                setOpenedListName("");
                setFilters("add", value.id, value);
                filterStore.setIsDiff(false);
              }}
              selectMulti={selectMulti}
              dictForArray={filterStore.dictForArrayValuesFilter}
              setDictForArray={filterStore.setDictForArrayValuesFilter}
              dateRangeFields={dateRangeFields}
            />
          ) : (
            ""
          )}
        </>
      </LoadedComponent>

      <Modal
        type="clarification"
        show={filterStore.showDeleteModal}
        onHide={() => {
          filterStore.setShowDeleteModal(false);
        }}
        title="Вы уверены, что хотите удалить фильтр?"
        btnWithCrossTitle="Удалить"
        btnWithCrossOnClick={() => {
          setFilters(
            "delete",
            downloadedFilter,
            filterStore.currentFilter as FilterType
          );
          filterStore.setShowDeleteModal(false);
          setQueryParams && setQueryParams({});
          setSearchParams({});
        }}
        blueBtnOnClick={() => {
          filterStore.setShowDeleteModal(false);
        }}
        blueBtnTitle="Отмена"
        btnWithCrossImg
      />
    </div>
  );
};

export default observer(FilterMain);
