import styles from "./staffOneInfoOneField.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import { ReactComponent as IconPrinter } from "shared/assets/images/mainIcons/iconPrinter.svg";
import ReactToPrint from "react-to-print";
import { fileUrl } from "stores/utils/consts";

import { CopyToClipboard } from "react-copy-to-clipboard";

import Avatar from "shared/assets/images/mainIcons/iconAvatar/Avatar.svg";
import { RefObject, useState } from "react";
import StaffOneInfoOneCompanies from "../StaffOneInfoOneCompanies";
import { DictType } from "modules/StaffModule/StaffOne/StaffOneInformation/StaffOneInfo/index";
import { ReactComponent as IconDash } from "shared/assets/images/iconStatus/Dash.svg";
import StatusIcon from "shared/ui/StatusIcon";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "widgets/LoadedComponent/Error/ErrorFallback";
import { getFormattedDate } from "shared/utils/helpers/getFormattedDate";

type StaffOneInfoOneFieldProps = {
  data: DictType;
  componentRef?: RefObject<null>;
  isObj?: boolean;
  setSelectedWorkerClothes?: (arg: string) => void;
  dismissCompany?: string;
};

const StaffOneInfoOneField = ({
  data,
  componentRef,
  isObj,
  setSelectedWorkerClothes,
  dismissCompany
}: StaffOneInfoOneFieldProps) => {
  const { staffOneStore } = useStores();

  const [isCopy, setIsCopy] = useState(false);

  const conditionOfPpe = ["size", "height", "shoe_size"];

  const tooltipStylesSuccess = {
    color: "Success",
    text: "Подтверждено"
  };

  const tooltipStylesError = {
    color: "Error",
    text: "Не подтверждено"
  };

  const tooltipStylesCopy = {
    color: "Blue",
    text: isCopy ? "✓ Адрес скопирован" : "Нажмите, чтобы скопировать адрес."
  };

  const getTitle = (title: string) => {
    switch (title) {
      case "age":
        return "Возраст";
      case "days":
        return "Дней обучения";
      case "guid_staff":
        return "1С ID";
      default:
        return staffOneStore.getTitleField(title);
    }
  };

  return (
    <>
      {Object.entries(data).map(([name, item], i) => {
        const objectOfData = typeof item === "object" && item !== null;

        switch (name) {
          case "photo":
            return (
              <div
                className={
                  styles.photoBlock +
                  (i === 0 ? " " + styles.photoBlock__first : "")
                }
                key={name}
              >
                <div className={styles.btnBlock}>
                  <div className={styles.TNBlock}>
                    <p className={styles.TNBlockTitle}>ТН</p>
                    <p className={styles.TNBlockValue}>{item["uid"]}</p>
                  </div>
                  <div className={styles.btnBlock__red}>
                    <StatusIcon icon="persondelete" color="accent-orange" />

                    <div
                      className={styles.tooltipBlue}
                      data-tooltip='Раздел "Уволить сотрудника" находится в разработке'
                    >
                      <a
                        //Раскомментировать, когда будет готов функционал по увольнению сотрудника
                        // href="https://processes.polati.ru/"
                        // target="_blank"
                        rel="noreferrer"
                      >
                        Уволить сотрудника
                      </a>
                    </div>
                  </div>
                  <ReactToPrint
                    trigger={() => (
                      <div className={styles.btnBlock__print}>
                        <IconPrinter className={styles.icon} />
                        <p>Распечатать</p>
                      </div>
                    )}
                    content={() => componentRef.current}
                  />
                </div>
                <div className={styles.textBlock}>
                  {staffOneStore.info["photo"]["src"] ? (
                    <img
                      className={styles.photo}
                      src={`${fileUrl}${
                        staffOneStore.info["photo"]["src"]
                      }?${Math.floor(Math.random() * 100000)}`}
                    />
                  ) : (
                    <img src={Avatar} />
                  )}
                  <p className={styles.textBlock_text}>
                    {item["surname"]} {item["name"]} {item["patronymic"]}
                  </p>
                </div>
              </div>
            );
          case "photo_alt":
            if (Object.values(staffOneStore.info["photo_alt"]).length) {
              let photoCounter = 0;
              return (
                <div
                  className={`${styles.field} ${styles.photo_alt} ${styles.field__border}`}
                  key={name}
                >
                  <div>
                    {Object.values(staffOneStore.info["photo_alt"]).map(
                      (photo) => {
                        if (photo["src"] !== -1) {
                          photoCounter += 1;
                          return (
                            photoCounter < 5 && (
                              <img
                                src={`${fileUrl}${photo["src"]}?${Math.floor(
                                  Math.random() * 100000
                                )}`}
                                key={photo["hash"]}
                              />
                            )
                          );
                        }
                      }
                    )}
                  </div>
                  <p>
                    Всего{" "}
                    {Object.values(staffOneStore.info["photo_alt"]).length}
                  </p>
                </div>
              );
            }
            break;
          case "phoneObj":
          case "guid_1c":
            return <StaffOneInfoOneField data={item} key={name} />;
          case "company":
            return (
              <ErrorBoundary FallbackComponent={ErrorFallback} key={name}>
                <StaffOneInfoOneCompanies />
              </ErrorBoundary>
            );
          case "confirm":
          case "phoneComment":
            if (data["phone"] || data["email"]) return;
            break;
          case "dismiss_object":
            if (Object.values(item).length) {
              return (
                <div
                  key={name}
                  className={`${styles.field} ${styles.field__border}`}
                >
                  <p className={styles.fieldTitle}>{getTitle(name)}</p>
                  <p className={styles.fieldValue}>{item[dismissCompany]}</p>
                </div>
              );
            } else return;

          case "black_list":
          case "grey_list":
            return (
              <div className={styles.row} key={name}>
                <div className={styles.field}>
                  <p className={styles.fieldTitle}>{getTitle(name)}</p>
                  <div
                    className={
                      name === "black_list"
                        ? styles.statusListBtn
                        : styles.statusListBtn_gray
                    }
                  >
                    {name === "black_list" ? "ЧС" : "СС"}
                  </div>
                </div>
                {(name === "black_list" &&
                  Object.keys(data).includes("black_reason")) ||
                (name === "grey_list" &&
                  Object.keys(data).includes("grey_reason")) ? (
                  <div className={styles.field}>
                    <p className={styles.fieldTitle}>
                      {getTitle(
                        name === "black_list" ? "black_reason" : "grey_reason"
                      )}
                    </p>
                    <p className={styles.fieldValue}>
                      {name === "black_list"
                        ? data["black_reason"]
                        : data["grey_reason"]}
                    </p>
                  </div>
                ) : (
                  ""
                )}
              </div>
            );
          case "black_reason":
          case "grey_reason":
            if (
              (name === "black_reason" &&
                !Object.keys(data).includes("black_list")) ||
              (name === "grey_reason" &&
                !Object.keys(data).includes("grey_list"))
            ) {
              return (
                <div className={styles.field}>
                  <p className={styles.fieldTitle}>{getTitle(name)}</p>
                  <p className={styles.fieldValue}>{item}</p>
                </div>
              );
            } else return;
          case "dismiss_date":
            return;
          case "snils":
            return (
              <div
                className={`${styles.field} ${
                  isObj ? "" : styles.field__border
                } `}
                key={name}
              >
                <p className={styles.fieldTitle}>{getTitle(name)}</p>
                <p className={styles.fieldValue}>
                  {item.trim().length ? (
                    item
                  ) : (
                    <span className={styles.hasNoSnils}>
                      <IconDash />
                      Нет
                    </span>
                  )}
                </p>
              </div>
            );
          default:
            if (objectOfData && item.key !== name) {
              return (
                <div className={styles.row} key={name}>
                  <StaffOneInfoOneField data={item} isObj />
                </div>
              );
            } else if (!(item.key === name && !item.value)) {
              return (
                <div
                  onMouseEnter={() => {
                    conditionOfPpe.includes(name)
                      ? setSelectedWorkerClothes(name)
                      : null;
                  }}
                  onMouseLeave={() => {
                    conditionOfPpe.includes(name)
                      ? setSelectedWorkerClothes("none")
                      : null;
                  }}
                  className={`${styles.field} ${
                    isObj ? "" : styles.field__border
                  } ${
                    conditionOfPpe.includes(name)
                      ? styles.field__border__ppe
                      : ""
                  } `}
                  style={{
                    cursor: conditionOfPpe.includes(name) ? "pointer" : ""
                  }}
                  key={name}
                >
                  <p className={styles.fieldTitle}>{getTitle(name)}</p>
                  {name === "email" || name === "phone" ? (
                    <div className="d-flex align-items-center flex-wrap">
                      {data["confirm"] === 1 ? (
                        <div
                          className={
                            styles[`tooltip${tooltipStylesSuccess.color}`]
                          }
                          data-tooltip={tooltipStylesSuccess.text}
                        >
                          <StatusIcon icon="bigcheck" color="accent-green" />
                        </div>
                      ) : data["confirm"] === 0 ? (
                        <div
                          className={
                            styles[`tooltip${tooltipStylesError.color}`]
                          }
                          data-tooltip={tooltipStylesError.text}
                        >
                          <StatusIcon icon="dash" color="accent-orange" />
                        </div>
                      ) : (
                        ""
                      )}

                      {name === "email" ? (
                        <div
                          className={
                            styles[`tooltip${tooltipStylesCopy.color}`]
                          }
                          data-tooltip={tooltipStylesCopy.text}
                        >
                          <CopyToClipboard text={item}>
                            <a
                              className={`${styles.fieldValue} ${styles.fieldValue__link}`}
                              onClick={() => setIsCopy(true)}
                              tabIndex={0}
                              onBlur={() => setIsCopy(false)}
                            >
                              {getFormattedDate(item)}
                            </a>
                          </CopyToClipboard>
                        </div>
                      ) : (
                        <>
                          <div className={styles.phone}>
                            <p>{getFormattedDate(item)}</p>
                          </div>
                          {data["phoneComment"] || data["emailComment"] ? (
                            <div className={styles.dataComment}>
                              {data["phoneComment"] || data["emailComment"]}
                            </div>
                          ) : (
                            ""
                          )}
                        </>
                      )}
                    </div>
                  ) : item === 1 && name !== "duty_allowance" ? (
                    name === "confirm" ? (
                      <div
                        className={
                          styles[`tooltip${tooltipStylesSuccess.color}`]
                        }
                        data-tooltip={tooltipStylesSuccess.text}
                      >
                        <StatusIcon icon="bigcheck" color="accent-green" />
                      </div>
                    ) : (
                      <StatusIcon icon="bigcheck" color="accent-green" />
                    )
                  ) : item === 0 && name !== "duty_allowance" ? (
                    name === "confirm" ? (
                      <div
                        className={styles[`tooltip${tooltipStylesError.color}`]}
                        data-tooltip={tooltipStylesError.text}
                      >
                        <StatusIcon icon="dash" color="accent-orange" />
                      </div>
                    ) : (
                      <StatusIcon icon="dash" color="accent-orange" />
                    )
                  ) : (
                    <p
                      className={`${styles.fieldValue} ${
                        name === "phone" ? styles.fieldValue__phone : ""
                      }`}
                    >
                      {getFormattedDate(item.key === name ? item.value : item)}
                    </p>
                  )}
                </div>
              );
            }
        }
      })}
    </>
  );
};

export default observer(StaffOneInfoOneField);
