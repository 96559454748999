import styles from "./staffImportTableBodyDuplicateError.module.scss";
import { useStores } from "stores";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import Tooltip from "shared/ui/Tooltip";
import { ReactComponent as Attention } from "shared/assets/images/iconStatus/Attention.svg";
import { classNames } from "shared/utils/helpers/classNames";
import { isEmpty } from "lodash";

type StaffImportTableBodyDuplicateErrorProps = {
  index: number;
  title?: string;
  innerDoubles?: boolean;
};

const StaffImportTableBodyDuplicateError = ({
  index,
  title,
  innerDoubles
}: StaffImportTableBodyDuplicateErrorProps) => {
  const { staffImportStore } = useStores();
  const navigate = useNavigate();

  let { uid, full_name, staff_id } = {
    uid: null,
    full_name: null,
    staff_id: null
  };

  if (!isEmpty(staffImportStore.verificationResponse[index]["doubles"])) {
    ({ uid, full_name, staff_id } = title
      ? staffImportStore.verificationResponse[index]["doubles"][title]
      : Object.values(
          staffImportStore.verificationResponse[index]["doubles"]
        )[0]);
  }

  return (
    <div
      className={classNames(styles.duplicateError, {
        [styles.duplicateErrorGrey]:
          !staffImportStore.duplicateColsResponse[index]?.includes(title)
      })}
    >
      <Attention />
      {uid && (
        <Tooltip
          text={
            <>
              <div>Нажмите чтобы перейти в профиль сотрудника: </div>{" "}
              <div>{`ТН${uid} ${full_name}`} </div>
            </>
          }
          borderColor="bw-gray6"
          color="bw-gray6"
          placement="top"
        >
          <p
            className={styles.duplicateErrorText}
            onClick={() => navigate(`../staff/id=${staff_id}`)}
          >{`ТН${uid}`}</p>
        </Tooltip>
      )}
      {innerDoubles && (
        <p className={styles.innerDuplicateErrorText}>Совпадения в данных</p>
      )}
    </div>
  );
};

export default observer(StaffImportTableBodyDuplicateError);
