import styles from "./staffListTable.module.scss";
import { useEffect, useRef } from "react";
import { useStores } from "stores/index";
import { observer } from "mobx-react-lite";

import LoadedComponent from "widgets/LoadedComponent";
import Loading from "widgets/LoadedComponent/Loading";
import { Table } from "react-bootstrap";
import ScrollButton from "shared/ui/Buttons/ScrollButton";
import { Link, useNavigate } from "react-router-dom";
import StatusIcon from "shared/ui/StatusIcon";

import { browserName } from "react-device-detect";
import { fileUrl } from "stores/utils/consts";
import { getTableMarginTop } from "shared/utils/helpers/getTableMarginTop";
import { getTHeadTop } from "shared/utils/helpers/getTHeadTop";
import { formatPhoneNumber } from "shared/utils/helpers/formatPhoneNumber";
import { Chips, ChipsVariation } from "shared/ui/Chips";

type StaffListTableProps = {
  handleClick: () => void;
  filterItems: (arg: string) => void;
};

const StaffListTable = observer(
  ({ filterItems, handleClick }: StaffListTableProps) => {
    const { staffListStore, menuStore, filterStore } = useStores();
    const navigate = useNavigate();
    const navigateUserProfile = (id: string) => {
      navigate(`/staff/id=${id}`);
      menuStore.setScrollPosition(menuStore.scroll);
    };
    const tableRef = useRef<HTMLTableElement>();

    useEffect(() => {
      if (
        menuStore.isScrollBottom &&
        staffListStore.maxPage >= staffListStore.page &&
        staffListStore.page === staffListStore.prevPage
      ) {
        staffListStore.setPage(staffListStore.page + 1);
      }
    }, [menuStore.isScrollBottom]);

    const getTitle = (key: string) => {
      if (
        Object.keys(staffListStore.staffColsAll).length &&
        staffListStore.staffColsAll[key]
      ) {
        return staffListStore.staffColsAll[key]["title"];
      } else return key;
    };

    return (
      <>
        {Object.values(staffListStore.staffColsAll).length &&
        staffListStore.currentTitles.length ? (
          <>
            <ScrollButton tableRef={tableRef} />
            <Table
              className={styles.table}
              style={{
                marginTop: getTableMarginTop(
                  filterStore.savedFiltersHeight,
                  Boolean(
                    !staffListStore.filters[staffListStore.downloadedFilter]?.[
                      "general"
                    ] || staffListStore.searchValue
                  ),
                  filterStore.isOpenAdvancedSearch ||
                    filterStore.isOpenedSavedFilterOptions ||
                    filterStore.isOpenedColumnsOptions,
                  browserName === "Firefox"
                ),
                transition: "0.2s"
              }}
              ref={tableRef}
            >
              <thead
                style={{
                  top: getTHeadTop(
                    filterStore.savedFiltersHeight,
                    Boolean(
                      !staffListStore.filters[
                        staffListStore.downloadedFilter
                      ]?.["general"] || staffListStore.searchValue
                    ),
                    filterStore.isOpenedSavedFilters &&
                      browserName === "Firefox"
                  )
                }}
              >
                <tr className={styles.row_white}>
                  {staffListStore.currentTitles.map((title) => {
                    const isSortedIcon =
                      !staffListStore.notShowAsColumnList.includes(title) &&
                      staffListStore.staffTableParams[title]?.useAsFilter ===
                        "on";

                    return (
                      <th
                        key={`th_${title}`}
                        className={`${styles.title} ${styles.pointer}`}
                      >
                        {isSortedIcon ? (
                          <div
                            className={styles.sortedIcon}
                            onClick={() => filterItems(title)}
                            id={`list_filter_${title}`}
                          >
                            <p className="m-0">{getTitle(title)}</p>
                            <StatusIcon
                              icon="iconarrowdouble"
                              color={
                                title === staffListStore.staffListOrder
                                  ? "blue-lazure"
                                  : "bw-gray5"
                              }
                            />
                          </div>
                        ) : (
                          <div className="d-flex align-items-center">
                            <p className="m-0">{getTitle(title)}</p>
                          </div>
                        )}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              {staffListStore.staffList.length ? (
                staffListStore.staffList.map((oneOfStaff, index) => {
                  const isGray = oneOfStaff.grey_list === 1;
                  const isBlack = oneOfStaff.black_list === 1;
                  if (typeof oneOfStaff.company !== "string") {
                    return (
                      <tbody key={oneOfStaff.id}>
                        {Object.keys(oneOfStaff.company).map(
                          (companyId, companyIdx) => {
                            return (
                              <tr
                                key={oneOfStaff.company[companyId]}
                                className={`${
                                  !(index % 2)
                                    ? styles["row_bw-gray1"]
                                    : styles["row_bw-white"]
                                } ${isBlack ? styles["row_bw-gray5"] : ""} ${
                                  isGray ? styles["row_bw-gray4"] : ""
                                }`}
                              >
                                {staffListStore.currentTitles.map((title) => {
                                  const isIconOk =
                                    staffListStore.staffColsAll[title][
                                      "type"
                                    ] === "bool" &&
                                    typeof oneOfStaff[title] !== "object" &&
                                    oneOfStaff[title] === 1;
                                  const isIconDash =
                                    staffListStore.staffColsAll[title][
                                      "type"
                                    ] === "bool";
                                  typeof oneOfStaff[title] !== "object" &&
                                    oneOfStaff[title] === 0;

                                  switch (title) {
                                    case "uid":
                                      return companyIdx === 0 ? (
                                        <td key={`${oneOfStaff.uid}_${title}`}>
                                          <div
                                            className={`${styles.wrapper} ${styles.tN}`}
                                            onClick={() =>
                                              navigateUserProfile(oneOfStaff.id)
                                            }
                                            id={`StaffListTable_goToStaffPage_${title}`}
                                          >
                                            <p className={styles.value}>
                                              {oneOfStaff[title]}
                                            </p>
                                            {isGray || isBlack ? (
                                              <Chips
                                                variation={
                                                  ChipsVariation.BLACKLIST
                                                }
                                                className={
                                                  isGray ? styles.gray : ""
                                                }
                                                title={isBlack ? "ЧС" : "СС"}
                                              />
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                        </td>
                                      ) : (
                                        <td
                                          className={styles.empty}
                                          key={`${oneOfStaff.uid}_${title}`}
                                        >
                                          <Link
                                            to={`/staff/id=${oneOfStaff.id}`}
                                            id={`stafflist_${oneOfStaff.id}_${title}`}
                                            onClick={handleClick}
                                            className={styles.link}
                                          >
                                            <br />
                                          </Link>
                                        </td>
                                      );
                                    case "surname":
                                    case "name":
                                    case "patronymic":
                                      return companyIdx === 0 ? (
                                        <td key={`${oneOfStaff.id}_${title}`}>
                                          <div
                                            className={styles.wrapper}
                                            onClick={() =>
                                              navigateUserProfile(oneOfStaff.id)
                                            }
                                            id={`StaffListTable_goToStaffPage_${title}`}
                                          >
                                            <p className={styles.value}>
                                              {oneOfStaff[title]}
                                            </p>
                                          </div>
                                        </td>
                                      ) : (
                                        <td
                                          key={`${oneOfStaff.uid}_${title}`}
                                          className={styles.empty}
                                        >
                                          <Link
                                            to={`/staff/id=${oneOfStaff.id}`}
                                            id={`stafflist_${oneOfStaff.id}_${title}`}
                                            onClick={handleClick}
                                            className={styles.link}
                                          >
                                            <br />
                                          </Link>
                                        </td>
                                      );
                                    case "event_type":
                                      return (
                                        <td key={`${oneOfStaff.id}_${title}`}>
                                          {oneOfStaff[title] ? (
                                            <Link
                                              to={`/staff/id=${oneOfStaff.id}`}
                                              id={`stafflist_${oneOfStaff.id}_${title}`}
                                              onClick={handleClick}
                                              className={styles.link}
                                            >
                                              <div className={styles.wrapper}>
                                                <StatusIcon
                                                  icon={
                                                    oneOfStaff[title][companyId]
                                                      ?.icon
                                                  }
                                                  color={
                                                    oneOfStaff[title][companyId]
                                                      ?.color
                                                  }
                                                />
                                                <p>
                                                  {
                                                    oneOfStaff[title][companyId]
                                                      ?.title
                                                  }
                                                </p>
                                              </div>
                                            </Link>
                                          ) : (
                                            ""
                                          )}
                                        </td>
                                      );
                                    case "company":
                                    case "position":
                                      return (
                                        <td key={`${oneOfStaff.id}_${title}`}>
                                          {oneOfStaff[title] !== null &&
                                          typeof oneOfStaff[title] ===
                                            "object" ? (
                                            <Link
                                              to={`/staff/id=${oneOfStaff.id}`}
                                              id={`stafflist_${oneOfStaff.id}_${title}`}
                                              onClick={handleClick}
                                              className={styles.link}
                                            >
                                              <div className={styles.wrapper}>
                                                <p>
                                                  {oneOfStaff[title][
                                                    companyId
                                                  ] || ""}
                                                </p>
                                              </div>
                                            </Link>
                                          ) : (
                                            ""
                                          )}
                                        </td>
                                      );
                                    case "building":
                                      return (
                                        <td key={`${oneOfStaff.id}_${title}`}>
                                          {oneOfStaff[title] !== null &&
                                          typeof oneOfStaff[title] ===
                                            "object" ? (
                                            <div className={styles.wrapper}>
                                              <p
                                                className={
                                                  styles.value_building
                                                }
                                              >
                                                <a
                                                  className={
                                                    styles.linkToBuilding
                                                  }
                                                  href={`${fileUrl}/crm/building/?id=${oneOfStaff[title][companyId]?.["id"]}`}
                                                  target="_blank"
                                                  rel="noreferrer"
                                                >
                                                  {oneOfStaff[title][
                                                    companyId
                                                  ]?.["title"] || ""}
                                                </a>
                                              </p>
                                            </div>
                                          ) : (
                                            // <Link
                                            //   id={`stafflist_${oneOfStaff.id}_${title}`}
                                            //   className={styles.linkToBuilding}
                                            //   to={`../building/id=${oneOfStaff[title][companyId]?.["id"]}/timesheet`}
                                            //   target="_blank"
                                            // >
                                            //   <div className={styles.wrapper}>
                                            //     <p>
                                            //       {oneOfStaff[title][
                                            //         companyId
                                            //       ]?.["title"] || ""}
                                            //     </p>
                                            //   </div>
                                            // </Link>
                                            ""
                                          )}
                                        </td>
                                      );
                                    case "phone":
                                      return (
                                        <td
                                          key={`${oneOfStaff.id}_${title}`}
                                          className={styles.maxContent}
                                        >
                                          <Link
                                            to={`/staff/id=${oneOfStaff.id}`}
                                            id={`stafflist_${oneOfStaff.id}_${title}`}
                                            onClick={handleClick}
                                            className={styles.link}
                                          >
                                            {Object.values(oneOfStaff[title])
                                              .length
                                              ? Object.values(
                                                  oneOfStaff[title]
                                                ).map((item) => (
                                                  <div
                                                    key={`${oneOfStaff.id}_${title}_${item}`}
                                                    className={`${styles.wrapper} ${styles.maxContent__wrapper}`}
                                                  >
                                                    {item["confirm"] ? (
                                                      <StatusIcon
                                                        icon="bigcheck"
                                                        color="accent-green"
                                                      />
                                                    ) : (
                                                      ""
                                                    )}
                                                    <p>
                                                      {formatPhoneNumber(
                                                        item["number"]
                                                      )}
                                                    </p>
                                                  </div>
                                                ))
                                              : ""}
                                          </Link>
                                        </td>
                                      );
                                    case "count_work_all":
                                      return (
                                        companyIdx === 0 && (
                                          <td
                                            key={oneOfStaff.uid}
                                            className={styles.countWorkAll}
                                            rowSpan={
                                              Object.values(oneOfStaff.company)
                                                .length
                                            }
                                          >
                                            <Link
                                              to={`/staff/id=${oneOfStaff.id}`}
                                              id={`stafflist_${oneOfStaff.id}_${title}`}
                                              onClick={handleClick}
                                              className={styles.link}
                                            >
                                              <div className={styles.wrapper}>
                                                {oneOfStaff.count_work_all}{" "}
                                              </div>
                                            </Link>
                                          </td>
                                        )
                                      );
                                    default:
                                      return companyIdx === 0 ? (
                                        <td key={`${oneOfStaff.id}_${title}`}>
                                          <Link
                                            to={`/staff/id=${oneOfStaff.id}`}
                                            id={`stafflist_${oneOfStaff.id}_${title}`}
                                            onClick={handleClick}
                                            className={`${styles.link} ${
                                              isIconOk ? styles.withOkIcon : ""
                                            }`}
                                          >
                                            {typeof oneOfStaff[title] !==
                                            "object" ? (
                                              <div className={styles.wrapper}>
                                                {isIconOk ? (
                                                  <StatusIcon
                                                    icon="bigcheck"
                                                    color="accent-green"
                                                  />
                                                ) : isIconDash ? (
                                                  ""
                                                ) : (
                                                  <p
                                                    style={
                                                      title === "comment"
                                                        ? {
                                                            wordBreak:
                                                              "break-word",
                                                            maxWidth: "200px"
                                                          }
                                                        : null
                                                    }
                                                  >
                                                    {oneOfStaff[title]}
                                                  </p>
                                                )}
                                              </div>
                                            ) : (
                                              ""
                                            )}
                                          </Link>
                                        </td>
                                      ) : (
                                        <td
                                          key={`${oneOfStaff.uid}_${title}`}
                                          className={styles.empty}
                                        >
                                          <Link
                                            to={`/staff/id=${oneOfStaff.id}`}
                                            id={`stafflist_${oneOfStaff.id}_${title}`}
                                            onClick={handleClick}
                                            className={styles.link}
                                          >
                                            <br />
                                          </Link>
                                        </td>
                                      );
                                  }
                                })}
                              </tr>
                            );
                          }
                        )}
                      </tbody>
                    );
                  } else {
                    return (
                      <tbody key={oneOfStaff.id}>
                        <tr
                          className={`${
                            !(index % 2)
                              ? styles["row_bw-gray1"]
                              : styles["row_bw-white"]
                          } ${isBlack ? styles["row_bw-gray5"] : ""} ${
                            isGray ? styles["row_bw-gray4"] : ""
                          }`}
                        >
                          {staffListStore.currentTitles.map((title) => {
                            const isIconOk =
                              staffListStore.staffColsAll[title]["type"] ===
                                "bool" &&
                              typeof oneOfStaff[title] !== "object" &&
                              oneOfStaff[title] === 1;
                            const isIconDash =
                              staffListStore.staffColsAll[title]["type"] ===
                              "bool";
                            typeof oneOfStaff[title] !== "object" &&
                              oneOfStaff[title] === 0;

                            switch (title) {
                              case "uid":
                                return (
                                  <td key={`${oneOfStaff.uid}_${title}`}>
                                    <div
                                      className={`${styles.wrapper} ${styles.tN}`}
                                      onClick={() =>
                                        navigateUserProfile(oneOfStaff.id)
                                      }
                                      id={`StaffListTable_goToStaffPage_${title}`}
                                    >
                                      <p className={styles.value}>
                                        {oneOfStaff[title]}
                                      </p>
                                      {isGray || isBlack ? (
                                        <Chips
                                          variation={ChipsVariation.BLACKLIST}
                                          className={isGray ? styles.gray : ""}
                                          title={isBlack ? "ЧС" : "СС"}
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </td>
                                );
                              case "surname":
                              case "name":
                              case "patronymic":
                                return (
                                  <td key={`${oneOfStaff.id}_${title}`}>
                                    <div
                                      className={styles.wrapper}
                                      onClick={() =>
                                        navigateUserProfile(oneOfStaff.id)
                                      }
                                      id={`StaffListTable_goToStaffPage_${title}`}
                                    >
                                      <p className={styles.value}>
                                        {oneOfStaff[title]}
                                      </p>
                                    </div>
                                  </td>
                                );
                              case "event_type":
                              case "company":
                              case "position":
                              case "building":
                                return (
                                  <td key={`${oneOfStaff.id}_${title}`}></td>
                                );

                              case "phone":
                                return (
                                  <td
                                    key={`${oneOfStaff.id}_${title}`}
                                    className={styles.maxContent}
                                  >
                                    <Link
                                      to={`/staff/id=${oneOfStaff.id}`}
                                      id={`stafflist_${oneOfStaff.id}_${title}`}
                                      onClick={handleClick}
                                      className={styles.link}
                                    >
                                      {Object.values(oneOfStaff[title]).length
                                        ? Object.values(oneOfStaff[title]).map(
                                            (item) => (
                                              <div
                                                key={`${oneOfStaff.id}_${title}_${item}`}
                                                className={`${styles.wrapper} ${styles.maxContent__wrapper}`}
                                              >
                                                {item["confirm"] ? (
                                                  <StatusIcon
                                                    icon="bigcheck"
                                                    color="accent-green"
                                                  />
                                                ) : (
                                                  ""
                                                )}
                                                <p>
                                                  {formatPhoneNumber(
                                                    item["number"]
                                                  )}
                                                </p>
                                              </div>
                                            )
                                          )
                                        : ""}
                                    </Link>
                                  </td>
                                );
                              case "count_work_all":
                                return (
                                  <td
                                    key={oneOfStaff.uid}
                                    className={styles.countWorkAll}
                                    rowSpan={
                                      Object.values(oneOfStaff.company).length
                                    }
                                  >
                                    <Link
                                      to={`/staff/id=${oneOfStaff.id}`}
                                      id={`stafflist_${oneOfStaff.id}_${title}`}
                                      onClick={handleClick}
                                      className={styles.link}
                                    >
                                      <div className={styles.wrapper}>
                                        {oneOfStaff.count_work_all}{" "}
                                      </div>
                                    </Link>
                                  </td>
                                );
                              default:
                                return (
                                  <td key={`${oneOfStaff.id}_${title}`}>
                                    <Link
                                      to={`/staff/id=${oneOfStaff.id}`}
                                      id={`stafflist_${oneOfStaff.id}_${title}`}
                                      onClick={handleClick}
                                      className={`${styles.link} ${
                                        isIconOk ? styles.withOkIcon : ""
                                      }`}
                                    >
                                      {typeof oneOfStaff[title] !== "object" ? (
                                        <div className={styles.wrapper}>
                                          {isIconOk ? (
                                            <StatusIcon
                                              icon="bigcheck"
                                              color="accent-green"
                                            />
                                          ) : isIconDash ? (
                                            ""
                                          ) : (
                                            <p
                                              style={
                                                title === "comment"
                                                  ? {
                                                      wordBreak: "break-word",
                                                      maxWidth: "200px"
                                                    }
                                                  : null
                                              }
                                            >
                                              {oneOfStaff[title]}
                                            </p>
                                          )}
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </Link>
                                  </td>
                                );
                            }
                          })}
                        </tr>
                      </tbody>
                    );
                  }
                })
              ) : (
                <tbody>
                  <tr className={styles.error}>
                    <td colSpan={staffListStore.currentTitles.length}>
                      <div data-type="error">
                        {staffListStore.errorMessage
                          ? staffListStore.errorMessage
                          : ""}
                      </div>
                    </td>
                  </tr>
                </tbody>
              )}
            </Table>
          </>
        ) : (
          ""
        )}
        {staffListStore.page !== 1 ? (
          <div className={styles.loading}>
            {staffListStore.isLoading ? <Loading withoutText /> : ""}
          </div>
        ) : (
          <LoadedComponent
            isLoading={staffListStore.isLoading}
            errorMessage={staffListStore.errorMessage}
            actionButton={staffListStore.actionButton}
          />
        )}
      </>
    );
  }
);

export default StaffListTable;
