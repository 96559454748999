import styles from "./staffImportTableBodyStatus.module.scss";
import { useStores } from "stores";
import { observer } from "mobx-react-lite";
import { StaffStatus } from "stores/StaffModule/types/StaffImportTable";
import { ReactComponent as IconUpdate } from "shared/assets/images/mainIcons/iconUpdate/iconUpdate.svg";
import iconExpect from "shared/assets/images/mainIcons/iconExpect.svg";
import circleCheck from "shared/assets/images/iconStatus/CircleCheck.svg";
import { ReactComponent as Attention } from "shared/assets/images/iconStatus/Attention.svg";
import StaffImportTableBodyDuplicateError from "../StaffImportTableBodyDuplicateError";

type StaffImportTableBodyStatusProps = {
  index: number;
  className: string;
};

const StaffImportTableBodyStatus = ({
  index,
  className
}: StaffImportTableBodyStatusProps) => {
  const { staffImportStore } = useStores();

  const statusRenderDict: Record<StaffStatus, JSX.Element> = {
    verificationRequired: (
      <td className={styles.statusCell_verificationRequired} data-no-hover>
        <div>
          <IconUpdate />
          <p>Требуется проверка</p>
        </div>
        {Object.values(staffImportStore.verificationResponse).length &&
        staffImportStore.initialStaffStatus[index] === "incorrectDouble" ? (
          <StaffImportTableBodyDuplicateError
            index={index}
            title={
              staffImportStore.duplicateColsResponse[index]?.length
                ? staffImportStore.duplicateColsResponse[index][0]
                : undefined
            }
          />
        ) : (
          ""
        )}
      </td>
    ),
    isLoadingDuplicateCheck: (
      <td className={styles.statusCell_isLoadingDuplicateCheck} data-no-hover>
        <div>
          <img src={iconExpect} alt="" />
          <p>Идёт проверка</p>
        </div>
      </td>
    ),
    incorrectField: (
      <td className={styles.statusCell_incorrectField} data-no-hover>
        <div>
          <Attention />
          <p>Некорректные данные</p>
        </div>
      </td>
    ),
    incorrectDouble: (
      <td className={styles.statusCell_incorrectDouble} data-no-hover>
        <div>
          {Object.values(
            staffImportStore.verificationResponse[index]?.["doubles"] || {}
          ).length ? (
            <StaffImportTableBodyDuplicateError
              index={index}
              title={
                staffImportStore.duplicateColsResponse[index]?.length
                  ? staffImportStore.duplicateColsResponse[index][0]
                  : undefined
              }
            />
          ) : (
            <div>
              <Attention />
              <p>Некорректные данные</p>
            </div>
          )}
        </div>
      </td>
    ),
    correct: (
      <td className={`${styles.statusCell_correct} ${className}`} data-no-hover>
        <div>
          <img src={circleCheck} alt="" />
          <p>Проверено</p>
        </div>
      </td>
    )
  };

  return statusRenderDict[staffImportStore.staffStatus[index]];
};

export default observer(StaffImportTableBodyStatus);
